import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
}));
export default function AlertDialog(props) {
	const classes = useStyles();
	const handleEnteredEvent = (e) => {
		if (!props?.isRequesting) {
			if (e.key === 'Enter') {
				props.onYes()
			} else if (e.key === 'Escape') {
				props.cancel()
			}
		}
	}
	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.closeDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onKeyDown={handleEnteredEvent}
			>
				<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{props.msg}
						{props?.RenderExtraContent?props?.RenderExtraContent():null}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.cancel} color="primary">
						الغاء
					</Button>
					{!props.isRequesting ? <Button
						disabled={props.isRequesting}
						onClick={props.onYes}
						color="primary"
						autoFocus
					>
						موافق
					</Button> : <div>
						<CircularProgress size={22}/>
					</div>
					}
				</DialogActions>
			</Dialog>
		</div>
	)
}
