import React, { useState, useEffect } from 'react'
import Table from '../../common/Table'
import NoData from '../../common/NoData'
import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import axios from 'axios'
import { getBaseURL, handleError,configureDate } from '../../common/Utils'
import CrudControl from '../../common/CrudControl'
import Modal from './AddUserModal'
import { getRoles, getGivenItems, getGivenItemsJson } from '../../common/ApiHelper'
import CPModal from './ChangePasswordModal'
import Snackbar from '../../common/SnackBar'
import EditModal from './editUserModal'
import Loading from '../../common/Loading'
import AclModal from './AclModal'


const url = getBaseURL()
const colsTitles = [
	'#',
	'اسم المستخدم',
	'الاسم الحقيقي',
	'الحالة',
	'الصلاحية',
	'ملاحظات',
	'الاليات',
	'الوجبات',
	'تغيير كلمة المرور',
	'تعديل',
	'الصلاحيات'
]

const vehicleTypesArr = [{ id: 1, name: 'ذاتي' }, { id: 2, name: 'مؤجر' }]

const NoDataDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-items: center;
`
const Root = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`

function Users(props) {
	const [users, setUsers] = useState([])
	const [filterUsers, setFilteredUsers] = useState([])
	const [updateUsers, setUpdateUsers] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [isRequesting, setIsRequesting] = useState(false)
	const [isError, setIsError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const [showModal, setShowModal] = useState(false) // for create
	const [showEditModal, setShowEditModal] = useState(false) // for edit modal
	const [requiredData, setRequiredData] = useState()
	const [totalVeh, setTotalVeh] = useState()
	const [alertError, setAlertError] = useState(false)
	const [alertErrorMsg, setAlertErrorMsg] = useState([])
	const [alertColor, setAlertColor] = useState('error')

	const [userFormData, setUserFormData] = useState({
		fullname: '',
		username: '',
		password: '',
		c_password: '',
		state: false,
		role: null,
		notes: ''
	})
	// for edit
	const [userEditFormData, setUserEditFormData] = useState({
		fullname: '',
		username: '',
		state: false,
		role: null,
		notes: ''
	})
	// resources
	const [roles, setRoles] = useState([])
	const [formErrors, setFormErrors] = useState([])

	//for change password
	const [showPasswordModal, setShowPasswordModal] = useState(false)
	const [newPassword, setNewPassword] = useState({
		password1: '',
		password2: ''
	})
	const [userToUpdate, setUserToUpdate] = useState({
		user: null,
		index: null
	})

	const [search, setSearch] = useState('')

	const [departments, setDepartments] = useState([])
	const [centers, setCenters] = useState([])
	const [selectedDepartment, setSelectedDepartment] = useState(null)
	const [selectedCenter, setSelectedCenter] = useState(null)
	const [vehicleTypes, setVehicleTypes] = useState(vehicleTypesArr)
	const [selectedVehicleType, setSelectedVehicleType] = useState(
		vehicleTypesArr[0]
	)
	const [vehicleClasses, setVehicleClasses] = useState([])
	const [selectedVehicleClass, setSelectedVehicleClass] = useState(null)

	const [checked, setChecked] = React.useState([])
	const [left, setLeft] = React.useState([])
	const [right, setRight] = React.useState([])

	const leftChecked = intersection(checked, left)
	const rightChecked = intersection(checked, right)

	const [showAclModal, setShowAclModal] = useState(false)

	const userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	if (
		userData.userInfo.role.name !== 'Admin' &&
		userData.userInfo.role.name !== 'Root'
	)
		window.location.href = '/'

	useEffect(() => {
		setIsLoading(true)
		const fetchResources = async () => {
			let tempRequired = await getGivenItemsJson({
				items:['users_stats'],
				from:configureDate(getInitFromDate()),
				to:configureDate(getInitFromDate())
			})
			let tempVeh = await getGivenItems(['vehicles'])
			let tempClasses = await getGivenItems(['classes'])
			let result = await getGivenItems(['departments'])
			if (!result.data || !tempClasses.data || !tempRequired.data) return

			// fetch each user required data of vehicles and frieght to be entered
			setRequiredData(tempRequired.data.Data.returned_items.users_stats)
			// fetch the total count of vehicles
			let vehCount = 0;
			tempVeh.data.Data.returned_items.vehicles.map(item=>{
				if(item.is_active === '1'){
					vehCount++;
				}
			})
			setTotalVeh(vehCount)
			let classes = tempClasses.data.Data.returned_items
			setVehicleClasses(classes.classes.length > 0 ? classes.classes : [])
			setSelectedVehicleClass(classes.classes.length > 0 ? classes.classes[0] : null)
			let arrays = result.data.Data.returned_items
			setDepartments(arrays.departments)
			setSelectedDepartment(
				arrays.departments.length > 0 ? arrays.departments[0] : null
			)
			setCenters(
				arrays.departments.length > 0 ? arrays.departments[0].centers : []
			)
			setSelectedCenter(
				arrays.departments.length > 0
					? arrays.departments[0].centers.length > 0
						? arrays.departments[0].centers[0]
						: null
					: null
			)
			let ri = []
			arrays.departments[0].centers.map(function(cen, ind) {
				ri.push({
					id: arrays.departments[0].id + '' + cen.id + '' + 1,
					dept_id: arrays.departments[0].id,
					center_id: cen.id,
					vehicle_type_id: 1,
					name: cen.name + ' - ' + 'ذاتي'
				})
				ri.push({
					id: arrays.departments[0].id + '' + cen.id + '' + 2,
					dept_id: arrays.departments[0].id,
					center_id: cen.id,
					vehicle_type_id: 2,
					name: cen.name + ' - ' + 'مؤجر'
				})
				return cen
			})

			setLeft(ri)
		}
		const getInitFromDate = () => {
			var my_date = new Date()
			var first_date = new Date(
				my_date.getFullYear(),
				my_date.getMonth(),
				my_date.getDate() - 1
			)
			return first_date
		}
		const fetchUsers = async () => {
			setIsError(false)
			let result = null
			try {
				result = await axios({
					url: url + `/users`,
					method: 'GET',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						Authorization: 'Bearer ' + userData.token
					},
					data: {}
				})
				setFilteredUsers(result.data.Data)
				setUsers(result.data.Data)
			} catch (error) {
				let errors = handleError(error)
				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
				}

				setIsError(true)
				setErrorMsg(errors)
			}
		}
		
		const fetchRoles = async () => {
			let result = await getRoles().then(result => result.data)
			if (result) setRoles(result.Data)
		}


		
		(async()=>{
			setIsLoading(true)
			// fetch users
		 

		// fetch roles
		 

		await Promise.all([fetchResources(),fetchUsers(),fetchRoles()])
			
		setIsLoading(false)
			
			})()
	}, [updateUsers])
	return (
		<Root>
			<CrudControl
				openModal={openModal}
				onSearchChange={onSearchChange}
				searchValue={search}
			/>
			{isLoading ? (
				<Loading />
			) : isError ? (
				<p>{errorMsg}</p>
			) : users.length === 0 ? (
				<NoDataDiv>
					<NoData />
					<IconButton onClick={props.fetchUsers}>
						<ReplayIcon />
					</IconButton>
				</NoDataDiv>
			) : (
				<Table
					header={colsTitles}
					data={filterUsers}
					totalVeh={totalVeh}
					requiredData={requiredData}
					openPasswordModal={openPasswordModal}
					block={block}
					showEditModalFun={showEditModalFun}
					showAclModalFun={showAclModalFun}
				></Table>
			)}
			<Modal
				show={showModal}
				hideModal={hideModal}
				userData={userFormData}
				isRequesting={isRequesting}
				changeState={changeState}
				create={create}
				roles={roles}
				changeField={changeField}
				changeRole={changeRole}
				formErrors={formErrors}
				onDepartmentChange={onDepartmentChange}
				departments={departments}
				selectedDepartment={selectedDepartment}
				selectedRole={userFormData.role}
				numberOfChecked={numberOfChecked}
				handleToggleAll={handleToggleAll}
				handleToggle={handleToggle}
				checked={checked}
				handleCheckedRight={handleCheckedRight}
				handleCheckedLeft={handleCheckedLeft}
				rightChecked={rightChecked}
				leftChecked={leftChecked}
				left={left}
				right={right}
			></Modal>
			{alertError ? (
				<Snackbar
					title="خطأ"
					msg={alertErrorMsg}
					show={alertError}
					period={6000}
					severity={alertColor}
					closeSnackbar={closeSnackbar}
				></Snackbar>
			) : null}
			{showPasswordModal ? (
				<CPModal
					closeCPModal={closeCPModal}
					newPassword={newPassword}
					changePassword={changePassword}
					showPasswordModal={showPasswordModal}
					user={userToUpdate}
					saveNewPassword={saveNewPassword}
				></CPModal>
			) : null}
			{showEditModal ? (
				<EditModal
					show={showEditModal}
					hideEditModal={hideEditModal}
					userData={userEditFormData}
					userToUpdate={userToUpdate}
					changeEditState={changeEditState}
					edit={edit}
					roles={roles}
					changeFieldForEdit={changeFieldForEdit}
					changeRoleForEdit={changeRoleForEdit}
					formErrors={formErrors}
				></EditModal>
			) : null}
			{showAclModal ? (
				<AclModal
					show={showAclModal}
					hideAclModal={hideAclModal}
					userToUpdate={userToUpdate}
					departments={departments}
					centers={centers}
					selectedDepartment={selectedDepartment}
					selectedCenter={selectedCenter}
					onAclDepartmentChange={onAclDepartmentChange}
					onAclCenterChange={onAclCenterChange}
					selectedVehicleType={selectedVehicleType}
					vehicleTypes={vehicleTypes}
					onVehicleTypeChange={onVehicleTypeChange}
					vehicleClasses={vehicleClasses}
					selectedVehicleClass={selectedVehicleClass}
					onVehicleClassChange={onVehicleClassChange}
					filterUsers={filterUsers}
				></AclModal>
			) : null}
		</Root>
	)
	function hideAclModal(e) {
		setShowAclModal(false)
		setUserToUpdate(null)
	}

	function not(a, b) {
		return a.filter(
			value =>
				b.findIndex(function(bItem) {
					return bItem.id == value.id
				}) === -1
		)
	}

	function union(a, b) {
		return [...a, ...not(b, a)]
	}
	function numberOfChecked(items) {
		return intersection(checked, items).length
	}

	function intersection(a, b) {
		return a.filter(
			value =>
				b.findIndex(function(bItem) {
					return bItem.id == value.id
				}) !== -1
		)
	}

	function handleToggle(value) {
		const currentIndex = checked.indexOf(value)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setChecked(newChecked)
	}

	function handleToggleAll(items) {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items))
		} else {
			setChecked(union(checked, items))
		}
	}

	function handleCheckedRight() {
		setRight(right.concat(leftChecked))
		setLeft(not(left, leftChecked))
		setChecked(not(checked, leftChecked))
	}

	function handleCheckedLeft() {
		setLeft(left.concat(rightChecked))
		setRight(not(right, rightChecked))
		setChecked(not(checked, rightChecked))
	}
	function onSearchChange(e) {
		setSearch(e.target.value)
		let filtered
		if (e.target.value.length) {
			filtered = users.filter(function(item) {
				return (
					item.username.toLowerCase().includes(search.toLowerCase()) ||
					item.name.toLowerCase().includes(search.toLowerCase())
				)
			})
			setFilteredUsers(filtered)
		} else {
			setFilteredUsers(users)
		}
	}

	function closeSnackbar(event, reason) {
		if (reason === 'clickaway') {
			return
		}
		setAlertError(false)
	}
	async function openModal(e) {
		if (roles.length > 0) {
			setFormErrors([])
			setShowModal(true)
		} else alert('خلل في تحميل الصلاحيات, يرجى اعادة تحميل الصفحة')
	}
	function hideModal(e) {
		setShowModal(false)
	}
	function hideEditModal(e) {
		// reset data inputs
		setUserEditFormData({
			...userEditFormData,
			id: 0,
			fullname: '',
			username: '',
			state: true,
			role: null,
			notes: ''
		})
		setShowEditModal(false)
	}
	function changeState(e) {
		setUserFormData({
			...userFormData,
			state: e.target.checked
		})
	}
	function changeEditState(e) {
		setUserEditFormData({
			...userEditFormData,
			state: e.target.checked
		})
	}

	function changeField(e) {
		setUserFormData({
			...userFormData,

			[e.target.name]: e.target.value
		})
	}
	function changeRole(e, value) {
		setUserFormData({
			...userFormData,

			role: value
		})
	}
	function create(e) {
		let result = validate()

		if (result.length === 0) {
			let data = {
				name: userFormData.fullname,
				username: userFormData.username,
				password: userFormData.password,
				c_password: userFormData.c_password,
				is_active: userFormData.state ? '1' : '0',
				role_id: userFormData.role.id
			}

			if (userFormData.notes.length > 0) {
				data = {
					...data,
					notes: userFormData.notes
				}
			}
			if (right.length > 0) {
				let arr = []
				for (let i = 0; i < right.length; i++) {
					arr.push({
						department_id: right[i].dept_id,
						center_id: right[i].center_id,
						vehicle_type_id: right[i].vehicle_type_id
					})
				}
				console.log('right is')
				console.log(arr)
				data = {
					...data,
					acl: arr
				}
			}
			makeRequest('users', data)
		} else setFormErrors(result)
	}

	function validate() {
		let errors = []

		if (!userFormData.role) errors.push('يجب اختيار نوع الصلاحية')

		if (userFormData.fullname.length < 4)
			errors.push('يجب ان يكون الاسم الحقيقي اكبر من 4 احرف')

		if (userFormData.username.length < 4)
			errors.push('يجب ان يكون اسم المستخدم اكبر من 4 احرف')

		if (userFormData.password.length < 4)
			errors.push('يجب ان تكون كلمة المرور اكبر من 4  احرف')

		if (userFormData.c_password.length < 4)
			errors.push('يجب ان تكون كلمة المرور المؤكدة اكبر من 4  احرف')

		if (userFormData.password !== userFormData.c_password)
			errors.push('كلمة المرور غير متطابقة')

		if (userFormData.role && userFormData.role.id > 2) {
			//
			if (right.length < 1) {
				errors.push('يجب اختيار الصلاحيات')
			}
		}

		return errors
	}

	function makeRequest(route, data) {
		setIsRequesting(true)
		setIsLoading(true)
		axios({
			url: url + `/` + route,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token
			},
			data: data
		})
			.then(response => {
				alert(' تم انشاء المستخدم بنجاح')
				let usersList = users
				usersList.push(response.data.Data.userInfo)
				setUsers(usersList)
				// clear inputs
				setUserFormData({
					...userFormData,

					fullname: '',
					username: '',
					password: '',
					c_password: '',
					state: true,
					role: null,
					notes: ''
				})

				setIsRequesting(false)
				setIsLoading(false)
				setFormErrors([])
				setSearch('')
			})
			.catch(error => {
				let errors = handleError(error)

				let errorsArr = [errors]
				setFormErrors(errorsArr)

				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
					return 401
				}
				setIsRequesting(false)
				setIsLoading(false)
			})
	}

	// edit actions
	function edit() {
		let result = editValidate()

		if (result.length === 0) {
			let data = {
				name: userEditFormData.fullname,
				username: userEditFormData.username,
				is_active: userEditFormData.state ? '1' : '0',
				role_id: userEditFormData.role.id,
				notes: userEditFormData.notes,
				_method: 'PUT'
			}

			editRequest(userEditFormData, data)
		} else setFormErrors(result)
	}

	function editValidate() {
		let errors = []

		if (!userEditFormData.role) errors.push('يجب اختيار نوع الصلاحية')

		if (userEditFormData.fullname.length < 4)
			errors.push('يجب ان يكون الاسم الحقيقي اكبر من 4 احرف')

		if (userEditFormData.username.length < 4)
			errors.push('يجب ان يكون اسم المستخدم اكبر من 4 احرف')

		return errors
	}
	// edit request
	function editRequest(userObj, data) {
		setFormErrors([])
		axios({
			url: url + `/users/` + userObj.id,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token
			},
			data: data
		})
			.then(response => {
				let usersList = users
				usersList[userObj.index] = response.data.Data
				setUsers(usersList)
				setUpdateUsers(!updateUsers)
				setUserEditFormData({
					...userFormData,
					index: null,
					id: null,
					fullname: '',
					username: '',
					state: true,
					role: null,
					notes: ''
				})
				setFormErrors([])
				setShowEditModal(false)
				setAlertError(true)
				setAlertErrorMsg('تم تعديل بيانات المستخدم بنجاح')
				setAlertColor('success')
				setSearch('')
			})
			.catch(error => {
				let errors = handleError(error)
				let errorsArr = [errors]
				setFormErrors(errorsArr)
				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
					return 401
				}
			})
	}
	function changeFieldForEdit(e) {
		setUserEditFormData({
			...userEditFormData,

			[e.target.name]: e.target.value
		})
	}
	function changeRoleForEdit(e, value) {
		setUserEditFormData({
			...userEditFormData,

			role: value
		})
	}
	function onVehicleTypeChange(e, value) {
		if (value) {
			setSelectedVehicleType(value)
		}
	}
	function onVehicleClassChange(e, value) {
		if (value){
			setSelectedVehicleClass(value)
		}
	}
	function onAclDepartmentChange(e, value) {
		if (value) {
			setSelectedDepartment(value)
			setCenters(value.centers && value.centers.length > 0 ? value.centers : [])
			setSelectedCenter(value.centers ? value.centers[0] : null)
		}
	}
	function onAclCenterChange(e, value) {
		if (value) {
			setSelectedCenter(value)
		}
	}
	function onDepartmentChange(e, value) {
		if (value) {
			let ri = []
			value.centers.map(function(cen, ind) {
				ri.push({
					id: value.id + '' + cen.id + '' + 1,
					dept_id: value.id,
					center_id: cen.id,
					vehicle_type_id: 1,
					name: cen.name + ' - ' + 'ذاتي'
				})
				ri.push({
					id: value.id + '' + cen.id + '' + 2,
					dept_id: value.id,
					center_id: cen.id,
					vehicle_type_id: 2,
					name: cen.name + ' - ' + 'مؤجر'
				})
				return cen
			})

			setLeft(ri)
			// setRight([])
			setUserEditFormData({
				...userEditFormData,
				selectedDepartment: value
			})
			setUserFormData({
				...userFormData,
				selectedDepartment: value
			})
		}
	}
	function showEditModalFun(userObj) {
		let userRole = roles.find(function(item) {
			return item.id === userObj.user.role.id
		})

		setUserEditFormData({
			...userEditFormData,
			index: userObj.index,
			id: userObj.user.id,
			fullname: userObj.user.name,
			username: userObj.user.username,
			state: userObj.user.is_active === '1' ? true : false,
			role: userRole,
			notes: userObj.user.notes
		})
		setShowEditModal(true)
		// setUserToUpdate(userObj)
	}
	function showAclModalFun(userObj) {
		setLeft([])
		setRight([])
		setChecked([])
		setSelectedDepartment(departments.length > 0 ? departments[0] : null)
		setCenters(
			departments.length > 0
				? departments[0].centers.length
					? departments[0].centers
					: []
				: []
		)
		setSelectedCenter(
			departments.length > 0
				? departments[0].centers.length > 0
					? departments[0].centers[0]
					: null
				: null
		)
		setUserToUpdate(userObj)
		setShowAclModal(true)
	}

	// end of edit actions
	function block(user, index) {
		axios({
			url: url + `/users/block/` + user.id,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token
			},
			data: {
				is_active: user.is_active === '1' ? '0' : '1'
			}
		})
			.then(response => {
				let usersList = users
				usersList[index] = response.data.Data

				setUsers(usersList)
				setUpdateUsers(!updateUsers)
			})
			.catch(error => {
				let errors = handleError(error)

				let errrorList = [errors]

				setAlertError(true)
				setAlertErrorMsg(errrorList)
				setAlertColor('error')

				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
					return 401
				}
			})
	}

	function changePassword(user, index, newPassword) {
		axios({
			url: url + `/users/cp/` + user.id,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token
			},
			data: {
				password: newPassword
			}
		})
			.then(response => {
				let usersList = users
				usersList[index] = response.data.Data

				setUsers(usersList)
				setUpdateUsers(!updateUsers)
			})
			.catch(error => {
				let errors = handleError(error)

				let errrorList = [errors]

				setAlertError(true)
				setAlertErrorMsg(errrorList)
				setAlertColor('error')

				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
					return 401
				}
			})
	}

	function openPasswordModal(user) {
		setShowPasswordModal(true)
		setUserToUpdate(user)
	}

	function changePassword(e) {
		setNewPassword({
			...newPassword,
			[e.target.name]: e.target.value
		})
	}
	function closeCPModal(e) {
		setShowPasswordModal(false)
		setNewPassword({
			password1: '',
			password2: ''
		})
	}

	function saveNewPassword(userObj) {
		if (!newPassword) {
			setAlertError(true)
			setAlertErrorMsg(['تأكد من ادخال كلمة المرور بصورة صحيحة'])
			setAlertColor('warning')
			return
		}
		if (newPassword.password1.length < 4 || newPassword.password2.length < 4) {
			setAlertError(true)
			setAlertErrorMsg(['يجب ان يكون طول كلمة المرور اكبر من 4 احرف'])
			setAlertColor('warning')
			return
		}
		if (newPassword.password1 !== newPassword.password2) {
			setAlertError(true)
			setAlertErrorMsg(['يجب ان تتطابق كلمة المرور'])
			setAlertColor('warning')
			return
		}

		// make request
		axios({
			url: url + `/users/cp/` + userObj.user.id,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token
			},
			data: {
				password: newPassword.password1,
				c_password: newPassword.password2
			}
		})
			.then(response => {
				let usersList = users
				usersList[userObj.index] = response.data.Data

				setUsers(usersList)
				setUpdateUsers(!updateUsers)
				// empty the inputs and hide
				setNewPassword({
					password1: '',
					password2: ''
				})
				setShowPasswordModal(false)
				setAlertError(true)
				setAlertErrorMsg('تم تحديث كلمة المرور الخاصة بالمستخدم')
				setAlertColor('success')
			})
			.catch(error => {
				let errors = handleError(error)

				let errrorList = [errors]

				setAlertError(true)
				setAlertErrorMsg(errrorList)
				setAlertColor('error')
				if (errors === 401) {
					window.location.href = '/'
					Cookies.remove('SDIMS_USER_DATA')
					return 401
				}
			})
	}
}

export default Users
