import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { getColor, getBaseURL, getSettings } from '../../common/Utils'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import Clock from 'react-live-clock'
import styled from 'styled-components'
import ProfileSection from '../../common/ProfileSection'
import ProfileMenu from '../../common/MenuItems'
import Context from '../../common/Context'
import { Redirect, NavLink } from 'react-router-dom'
import Cookies from 'js-cookie'
import AssessmentIcon from '@material-ui/icons/Assessment'
import TimerIcon from '@material-ui/icons/Timer'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import ApartmentIcon from '@material-ui/icons/Apartment';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import GroupIcon from '@material-ui/icons/Group'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import FunctionsIcon from '@material-ui/icons/Functions'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import WcIcon from '@material-ui/icons/Wc'
import StorageIcon from '@material-ui/icons/Storage'
import SettingsIcon from '@material-ui/icons/Settings'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import Login from '../../pages/login/Login'
import Dashboard from '../../pages/Dashboard/Dashboard'
import Reports from '../../pages/Reports/Reports'
import Shifts from '../../pages/Shifts/Shifts'
import Reasons from '../../pages/Reasons/Reasons'
import Centers from '../../pages/Centers/Centers'
import Users from '../Users/Users'
import CrudFrame from '../../common/CrudFrame'
import Vehicles from '../../pages/Vehicles/Vehicles'
import Drivers from '../../pages/Drivers/Drivers'
import Freights from '../../pages/Freights/Freights'
import Notes from '../../pages/Notes/Notes'
import WeightNotes from '../../pages/WeightNotes/WeightNotes'
import VehiclesNotAllow from '../VehiclesNotAllow/VehiclesNotAllow'
import BlockIcon from '@material-ui/icons/Block'
import VehicleClass from '../VehicleClasses/VehicleClass'
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck'
import NameOfOfficials from '../NameOfOfficials/NameOfOfficials'
import GavelIcon from '@material-ui/icons/Gavel'
import Departments from '../Departments/Departments'
const providerTheme = createMuiTheme({
	direction: 'ltr',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(','),
	},
})

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		backgroundColor: `${getColor('primary').dark}`,
		maxHeight: '64px',
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: drawerWidth,
	},
	title: {
		position: 'absolute',
		left: '50%',
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-start',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginRight: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
	listItems: {
		textAlign: 'right',
		paddingRight: '10px',
	},
	item: {
		'&:hover': {
			background: '#75CDFF',
		},
		'&:active': {
			background: '#75CDFF',
		},
	},
	titleDiv: {
		marginLeft: '10px',
		marginRight: '10px',
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'baseline',
		justifyContent: 'flex-end',
		flex: '1',
	},
	appTitle: {
		[theme.breakpoints.down('md')]: {
			opacity: 0,
		},
	},
}))

const ClockTime = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 15px;
`
const SubToolBar = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
`
export default function PersistentDrawerRight(props) {
	const classes = useStyles()
	const theme = useTheme()
	const [open, setOpen] = React.useState(
		props.match.path === '/freights' ? false : true
	)
	const [showProfileMenu, setShowProfileMenu] = React.useState(false)
	const [anchorEl, setAnchorEl] = React.useState(null)
	let data = null
	try {
		data = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}

	let appTitle = ''

	appTitle = getSettings().app.title

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	const renderPage = (props) => {
		if (props.match.path === '/') {
			return <Login />
		}
		if (props.match.path === '/dashboard') {
			return <Dashboard />
		}
		if (props.match.path === '/reports') {
			return <Reports />
		}
		if (props.match.path === '/shifts') {
			return <Shifts />
		}
		if (props.match.path === '/centers') {
			return <Centers />
		}
		if (props.match.path === '/reasons') {
			return <Reasons showCenter={true} />
		}
		if (props.match.path === '/users') {
			return <Users />
		}
		if (props.match.path === '/vehicles') {
			return <Vehicles />
		}
		if (props.match.path === '/drivers') {
			return <Drivers />
		}
		if (props.match.path === '/freights') {
			return <Freights />
		}
		if (props.match.path === '/notes') {
			return <Notes />
		}
		if (props.match.path === '/Vehicle-classes') {
			return <VehicleClass />
		}
		// if (props.match.path === '/WeightNotes') {
		// 	return <WeightNotes />
		// }
		if (props.match.path === '/VehiclesNotAllow') {
			return <VehiclesNotAllow />
		}
		if (props.match.path === '/name-of-officials') {
			return <NameOfOfficials />
		}
		if (props.match.path === '/departments') {
			return <Departments />
		}
	}
	const getTitle = (props) => {
		switch (props.match.path) {
			case '/dashboard':
				return 'الاحصائيات'
			case '/users':
				return 'ادارة المستخدمين'
			case '/vehicles':
				return 'ادارة المركبات'
			case '/drivers':
				return 'ادارة السائقين'
			case '/freights':
				return 'ادارة الشحنات'
			case '/reports':
				return 'التقارير'
			case '/centers':
				return 'المراكز والشعب'
			// case '/notes':
			// 	return 'ملاحظات الحضورية الشهرية'
			// case '/WeightNotes':
			// 	return 'ملاحظات تقرير الاوزان حسب الايام'
			case '/VehiclesNotAllow':
				return 'الاليات التي ليس لها شحنات اليوم'
			case '/Vehicle-classes':
				return 'أصناف الأليات'
			case '/name-of-officials':
				return 'أسماء المسؤولين'
			case '/departments':
				return 'الاقسام'
			default:
				return '----'
		}
	}
	if (!data || !data.token) return <Redirect to="/"></Redirect>

	return (
		// <Context.Consumer>
		// {(ctx) => {

		// return (
		<ThemeProvider theme={providerTheme}>
			<div dir="ltr" className={classes.root}>
				<CssBaseline />
				<AppBar
					position="fixed"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: open,
					})}
				>
					<Toolbar>
						<SubToolBar>
							<ProfileSection
								showProfileMenu={onProfileClick}
								data={data}
							></ProfileSection>
							<ProfileMenu
								showMenu={showProfileMenu}
								anchorEl={anchorEl}
								handleCloseProfileMenu={onProfileClick}
							></ProfileMenu>

							<div className={classes.titleDiv}>
								<div className={classes.title}>
									<h3>{getTitle(props)}</h3>
								</div>
								<div className={classes.appTitle}>
									<h3>{appTitle}</h3>
								</div>
							</div>
						</SubToolBar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="end"
							onClick={handleDrawerOpen}
							className={clsx(open && classes.hide)}
						>
							<MenuIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<main
					style={{
						height: 'calc(100vh - 64px)',
						backgroundColor: '#DEF3FF',
						width: '100%',
					}}
					dir="rtl"
					className={clsx(classes.content, {
						[classes.contentShift]: open,
					})}
				>
					<div className={classes.drawerHeader} />
					{renderPage(props)}
				</main>
				<Drawer
					dir="rtl"
					className={classes.drawer}
					variant="persistent"
					anchor="right"
					open={open}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					<div className={classes.drawerHeader}>
						<IconButton onClick={handleDrawerClose}>
							{theme.direction === 'rtl' ? (
								<ChevronLeftIcon />
							) : (
								<ChevronRightIcon />
							)}
						</IconButton>
						<ClockTime>
							<Clock
								format={'HH:mm:ss'}
								ticking={true}
								timezone={'Asia/Baghdad'}
								style={{ fontSize: '1.5em' }}
							/>
						</ClockTime>
					</div>
					<Divider />

					<List>
						{data.userInfo.role.name === 'Admin' ||
						data.userInfo.role.name === 'Root' ? (
							<NavLink
								className="menu-items"
								to="/dashboard"
								activeClassName="active"
							>
								<ListItem className={classes.item} button>
									<EqualizerIcon style={{ color: '#007AC4' }} />
									<ListItemText
										className={classes.listItems}
										primary={'الاحصائيات'}
									/>
								</ListItem>
							</NavLink>
						) : null}

						{data.userInfo.role.name === 'Admin' ||
						data.userInfo.role.name === 'Root' ||
						data.userInfo.role.name === 'Reporter' ||
						data.userInfo.role.name === 'Reporter & Data Entry' ? (
							<NavLink className="menu-items" to="/reports">
								<ListItem className={classes.item} button>
									<AssessmentIcon style={{ color: '#007AC4' }} />
									<ListItemText
										className={classes.listItems}
										primary={'التقارير'}
									/>
								</ListItem>
							</NavLink>
						) : null}
					</List>
					<Divider />
					<span
						style={{
							marginTop: '10px',
							marginRight: '20px',
							color: '#A7A7A7',
						}}
					>
						اعدادات الحساب
					</span>
					<List>
						{data.userInfo.role.name === 'Admin' ||
						data.userInfo.role.name === 'Root' ? (
							<React.Fragment>
								{/* <ListItem disabled={true} className={classes.item} button>
												<TimerIcon style={{ color: '#007AC4' }} />
												<ListItemText
													className={classes.listItems}
													primary={'الوجبات'}
												/>
											</ListItem> */}
								<NavLink className="menu-items" to="/departments">
									<ListItem className={classes.item} button>
										<ApartmentIcon style={{ color: '#007AC4' }} />
										<ListItemText
											className={classes.listItems}
											primary={'الاقسام'}
										/>
									</ListItem>
								</NavLink>
								<NavLink className="menu-items" to="/centers">
									<ListItem className={classes.item} button>
										<HomeWorkIcon style={{ color: '#007AC4' }} />
										<ListItemText
											className={classes.listItems}
											primary={'المراكز البلدية'}
										/>
									</ListItem>
								</NavLink>
								{/* <ListItem disabled={true} className={classes.item} button>
												<PlaylistAddCheckIcon style={{ color: '#007AC4' }} />
												<ListItemText
													className={classes.listItems}
													primary={'اسباب الغياب'}
												/>
											</ListItem> */}
								<NavLink className="menu-items" to="/users">
									<ListItem className={classes.item} button>
										<GroupIcon style={{ color: '#007AC4' }} />
										<ListItemText
											className={classes.listItems}
											primary={'المستخدمين'}
										/>
									</ListItem>
								</NavLink>
							</React.Fragment>
						) : null}
					</List>
					<Divider />
					<span
						style={{
							marginTop: '10px',
							marginRight: '20px',
							color: '#A7A7A7',
						}}
					>
						ادارة مصادر النظام
					</span>
					<List>
						{data.userInfo.role.name === 'Admin' ||
						data.userInfo.role.name === 'Root' ? (
							<React.Fragment>
								<NavLink className="menu-items" to="/name-of-officials">
									<ListItem className={classes.item} button>
										<GavelIcon style={{ color: '#007AC4' }} />
										<ListItemText
											className={classes.listItems}
											primary={'المسؤولين'}
										/>
									</ListItem>
								</NavLink>
								<NavLink className="menu-items" to="/vehicles">
									<ListItem className={classes.item} button>
										<LocalShippingIcon style={{ color: '#007AC4' }} />
										<ListItemText
											className={classes.listItems}
											primary={'الاليات'}
										/>
									</ListItem>
								</NavLink>
								<NavLink className="menu-items" to="/Vehicle-classes">
									<ListItem className={classes.item} button>
										<LibraryAddCheckIcon style={{ color: '#007AC4' }} />
										<ListItemText
											className={classes.listItems}
											primary={'أصناف الاليات'}
										/>
									</ListItem>
								</NavLink>

								<NavLink
									className="menu-items"
									to="/VehiclesNotAllow"
									onClick={removesearchhere}
								>
									<ListItem className={classes.item} button>
										<BlockIcon style={{ color: '#007AC4' }} />
										<ListItemText
											className={classes.listItems}
											primary={'الاليات التي ليس لها شحنات اليوم'}
										/>
									</ListItem>
								</NavLink>

								<NavLink className="menu-items" to="/drivers">
									<ListItem className={classes.item} button>
										<PermIdentityIcon style={{ color: '#007AC4' }} />
										<ListItemText
											className={classes.listItems}
											primary={'السائقين'}
										/>
									</ListItem>
								</NavLink>
								{/* <NavLink className="menu-items" to="/notes">
												<ListItem className={classes.item} button>
													<NoteAddIcon style={{ color: '#007AC4' }} />
													<ListItemText
														className={classes.listItems}
														primary={'ملاحظات الحضورية الشهرية'}
													/>
												</ListItem>
											</NavLink> */}
								{/* <NavLink className="menu-items" to="/WeightNotes">
												<ListItem className={classes.item} button>
													<FunctionsIcon style={{ color: '#007AC4' }} />
													<ListItemText
														className={classes.listItems}
														primary={'ملاحظات الاوزان'}
													/>
												</ListItem>
											</NavLink> */}
								{/* <ListItem disabled={true} className={classes.item} button>
												<WcIcon style={{ color: '#007AC4' }} />
												<ListItemText
													className={classes.listItems}
													primary={'الموظفين'}
												/>
											</ListItem> */}
							</React.Fragment>
						) : (
							<React.Fragment>
								<NavLink
									className="menu-items"
									to="/VehiclesNotAllow"
									onClick={removesearchhere}
								>
									<ListItem className={classes.item} button>
										<BlockIcon style={{ color: '#007AC4' }} />
										<ListItemText
											className={classes.listItems}
											primary={'الاليات التي ليس لها شحنات اليوم'}
										/>
									</ListItem>
								</NavLink>
							</React.Fragment>
						)}
					</List>
					<Divider />
					<span
						style={{
							marginTop: '10px',
							marginRight: '20px',
							color: '#A7A7A7',
						}}
					>
						ادخال البيانات
					</span>
					<List>
						{data.userInfo.role.name === 'Data Entry' ||
						data.userInfo.role.name === 'Admin' ||
						data.userInfo.role.name === 'Root' ||
						data.userInfo.role.name === 'Reporter & Data Entry' ? (
							<NavLink
								className="menu-items"
								to="/freights"
								onClick={handleDrawerClose}
							>
								<ListItem className={classes.item} button>
									<StorageIcon style={{ color: '#007AC4' }} />
									<ListItemText
										className={classes.listItems}
										primary={'الشحنات اليومية'}
									/>
								</ListItem>
							</NavLink>
						) : null}
					</List>
					<Divider />
					<span
						style={{
							marginTop: '10px',
							marginRight: '20px',
							color: '#A7A7A7',
						}}
					>
						اخرى
					</span>
					<List>
						{data.userInfo.role.name === 'Root' ||
						data.userInfo.role.name === 'Admin'
							? // <ListItem disabled={true} className={classes.item} button>
							  // 	<SettingsIcon style={{ color: '#007AC4' }} />
							  // 	<ListItemText
							  // 		className={classes.listItems}
							  // 		primary={'اعدادات البرنامج '}
							  // 	/>
							  // </ListItem>
							  null
							: null}
					</List>
				</Drawer>
			</div>
		</ThemeProvider>
		// )
		// }}
		// </Context.Consumer>
	)

	function onProfileClick(e) {
		setShowProfileMenu(!showProfileMenu)
		if (e) {
			setAnchorEl(e.currentTarget)
		}
	}

	function removesearchhere() {
		if (localStorage.getItem('searchNotCompleteFreightsd')) {
			localStorage.removeItem('searchNotCompleteFreightsd')
		}
	}
}
