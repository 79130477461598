import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Slide from '@material-ui/core/Slide'
import Snackbar from '../../common/SnackBar'

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'

import TextField from '@material-ui/core/TextField'
import Loading from '../../common/Loading'
import Radio from '@material-ui/core/Radio'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'
import axios from 'axios'
import Cookies from 'js-cookie'
import {
	formatErrorMgs2,
	getBaseURL,
	getStatusCode,
	handleErr,
} from '../../common/Utils'
const url = getBaseURL()

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const colors = require('../../../assets/files/env.json').theme.colors

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(','),
	},
})

const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
`
const SubFContainers = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	flex-wrap: wrap;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
	background-color: '#fff';
`

const DataDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
	padding: 5px;
	border: 1px solid #9c9c9c;
	width: 100%;
	border-radius: 5px;
`

const useStyles = makeStyles((theme) => ({
	autocomplete: {
		marginTop: '15px',
		// minWidth: '500px',
		backgroundColor: '#fff',
	},
	pickers: {
		backgroundColor: '#fff',
	},
	textField: {
		backgroundColor: '#fff',
		marginTop: '10px',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	appBar: {
		position: 'relative',
	},
}))
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})
function PaperComponent(props) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper
				{...props}
				style={{
					width: '60%',
					height: '60%',
				}}
			/>
		</Draggable>
	)
}

export default function DraggableDialog(props) {
	const [vehicleClasses, setUpdateVehicleClasse] = useState([])
	const [departmentVehicleClasses, setDepartmentVehicleClasses] = useState([])
	const [loading, setLoading] = useState(false)
	const classes = useStyles()
	const handleEnteredEvent = (e) => {
		if (!props?.isRequesting) {
			if (e.key === 'Enter') {
				props.save() // Handle the event for other elements
			} else if (e.key === 'Escape') {
				props.closeAddModal()
			}
		}
	}
	const [alert, setAlert] = useState({
		show: false,
		color: 'error',
		title: '',
		msg: '',
	})
	const datalist = [
		{
			id: 1,
			name: '\u0643\u0627\u0628\u0633\u0627\u062a',
			descr: null,
			note_thatee:
				'\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627\n \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			note_moajar:
				'\u062d\u0633\u0628 \u0627\u0644\u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627\n \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			created_at: null,
			updated_at: '2024-04-30T23:10:23.000000Z',
		},
		{
			id: 2,
			name: '\u0631\u0627\u0641\u0639\u0629 \u062d\u0627\u0648\u064a\u0627\u062a',
			descr: null,
			note_thatee:
				'\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627\n \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			note_moajar:
				'\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627\n \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			created_at: null,
			updated_at: '2024-04-30T23:11:14.000000Z',
		},
		{
			id: 3,
			name: '\u0647\u0627\u064a \u0627\u0628',
			descr: null,
			note_thatee: null,
			note_moajar: null,
			created_at: null,
			updated_at: null,
		},
		{
			id: 4,
			name: '\u0633\u0643\u0633 \u0642\u0644\u0627\u0628',
			descr: null,
			note_thatee:
				'\u0633\u0643\u0633 \u0642\u0644\u0627\u0628 3 \u0634\u062d\u0646\u0647 \u0644\u0643\u0644 \u0648\u062c\u0628\u0647 \u0639\u0645\u0644 \n\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627\n \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			note_moajar:
				'\u0633\u0643\u0633 \u0642\u0644\u0627\u0628 3 \u0634\u062d\u0646\u0647 \u0644\u0643\u0644 \u0648\u062c\u0628\u0647 \u0639\u0645\u0644\n  \u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627\n \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			created_at: null,
			updated_at: '2024-04-30T23:12:06.000000Z',
		},
		{
			id: 5,
			name: '\u0627\u0644\u0643\u064a\u0627',
			descr: null,
			note_thatee: null,
			note_moajar:
				'\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627 \u0627\u0644\u0643\u064a\u0627 3 \u0634\u062d\u0646\u0627\u062a \u0644\u0644\u0648\u062c\u0628\u0647 \u0627\u0644\u0648\u0627\u062d\u062f\u0647\n \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			created_at: null,
			updated_at: '2024-04-30T23:12:59.000000Z',
		},
		{
			id: 6,
			name: '\u0627\u0644\u0634\u0641\u0644',
			descr: null,
			note_thatee:
				'\u0627\u0644\u0634\u0641\u0644\u0627\u062a \u062d\u0633\u0628 \u0633\u0627\u0639\u0627\u062a \u0627\u0644\u062d\u0631\u0643\u0647 \u0648 \u0627\u0644\u0645\u062d\u0631\u0643 \u0627\u0644\u0645\u0631\u0641\u0642\u0647 \u0637\u064a\u0627\n  \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			note_moajar:
				'\u0627\u0644\u0634\u0641\u0644\u0627\u062a \u062d\u0633\u0628 \u0633\u0627\u0639\u0627\u062a \u0627\u0644\u062d\u0631\u0643\u0647 \u0648 \u0627\u0644\u0645\u062d\u0631\u0643 \u0627\u0644\u0645\u0631\u0641\u0642\u0647 \u0637\u064a\u0627\n \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			created_at: null,
			updated_at: '2024-04-30T23:13:40.000000Z',
		},
		{
			id: 7,
			name: '\u0627\u0633\u062a\u0631\u0627\u062a',
			descr: null,
			note_thatee:
				'\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627 \n \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			note_moajar:
				'\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627\n  \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			created_at: null,
			updated_at: '2024-04-30T23:13:49.000000Z',
		},
		{
			id: 8,
			name: '\u0635\u0627\u0631\u0648\u062e\u064a\u0629',
			descr: null,
			note_thatee: null,
			note_moajar: null,
			created_at: null,
			updated_at: null,
		},
		{
			id: 9,
			name: '\u0634\u0627\u0641\u0637\u0627\u062a',
			descr: null,
			note_thatee: null,
			note_moajar: null,
			created_at: null,
			updated_at: null,
		},
		{
			id: 10,
			name: '\u0633\u0627\u062d\u0628\u0627\u062a',
			descr: null,
			note_thatee:
				'\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627 \u0627\u0644\u0633\u0627\u062d\u0628\u0647 3 \u0634\u062d\u0646\u0627\u062a \u0644\u0644\u0648\u062c\u0628\u0647 \u0627\u0644\u0648\u0627\u062d\u062f\u0647\n \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			note_moajar: null,
			created_at: null,
			updated_at: '2024-04-30T23:14:00.000000Z',
		},
		{
			id: 11,
			name: '\u062d\u0641\u0627\u0631\u0629',
			descr: null,
			note_thatee:
				'\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u062d\u0631\u0643\u0647 \u0648 \u0627\u0644\u0645\u062d\u0631\u0643 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627 \u0627\u0644\u0633\u0627\u062d\u0628\u0647 \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			note_moajar:
				'\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u062d\u0631\u0643\u0647 \u0648 \u0627\u0644\u0645\u062d\u0631\u0643 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627 \u0627\u0644\u0633\u0627\u062d\u0628\u0647 \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			created_at: null,
			updated_at: '2024-05-02T08:31:18.000000Z',
		},
		{
			id: 12,
			name: '\u0643\u0627\u0646\u0633\u0629',
			descr: null,
			note_thatee: null,
			note_moajar:
				'\u0627\u0644\u0643\u0627\u0646\u0633\u0627\u062a \u062d\u0633\u0628 \u0633\u0627\u0639\u0627\u062a \u0627\u0644\u062d\u0631\u0643\u0647 \u0648 \u0627\u0644\u0645\u062d\u0631\u0643 \u0627\u0644\u0645\u0631\u0641\u0642\u0647 \u0637\u064a\u0627 \n\u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			created_at: null,
			updated_at: '2024-05-02T20:38:46.000000Z',
		},
		{
			id: 13,
			name: '\u062a\u0646\u0643\u0631',
			descr: null,
			note_thatee: null,
			note_moajar: null,
			created_at: null,
			updated_at: null,
		},
		{
			id: 14,
			name: '\u062a\u0643 \u0642\u0644\u0627\u0628',
			descr: null,
			note_thatee:
				'\u062a\u0643 \u0642\u0644\u0627\u0628 3 \u0634\u062d\u0646\u0627\u062a \u0644\u0643\u0644 \u0648\u062c\u0628\u0647 \u0639\u0645\u0644\n \u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627 \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			note_moajar:
				'\u062a\u0643 \u0642\u0644\u0627\u0628 3 \u0634\u062d\u0646\u0627\u062a \u0644\u0643\u0644 \u0648\u062c\u0628\u0647 \u0639\u0645\u0644\n \u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627 \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			created_at: null,
			updated_at: '2024-04-30T23:14:41.000000Z',
		},
		{
			id: 15,
			name: '\u0646\u0633\u0627\u0641 \u0642\u0644\u0627\u0628',
			descr: '\u0646\u0633\u0627\u0641 \u0642\u0644\u0627\u0628',
			note_thatee:
				'\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627 \u0627\u0644\u0633\u0627\u062d\u0628\u0647  \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			note_moajar:
				'\u062d\u0633\u0628 \u062a\u0642\u0631\u064a\u0631 \u0627\u0644\u0627\u0648\u0632\u0627\u0646 \u0627\u0644\u0645\u0631\u0641\u0642 \u0637\u064a\u0627 \u0627\u0644\u0633\u0627\u062d\u0628\u0647  \u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			created_at: '2023-06-27T07:00:47.000000Z',
			updated_at: '2024-04-30T16:09:35.000000Z',
		},
		{
			id: 16,
			name: '\u0643\u0631\u064a\u062f\u0631',
			descr: '\u0643\u0631\u064a\u062f\u0631',
			note_thatee: null,
			note_moajar:
				'\u0627\u0644\u0643\u0631\u064a\u062f\u0631 \u062d\u0633\u0628 \u0633\u0627\u0639\u0627\u062a \u0627\u0644\u062d\u0631\u0643\u0647 \u0648 \u0627\u0644\u0645\u062d\u0631\u0643 \u0627\u0644\u0645\u0631\u0641\u0642\u0647 \u0637\u064a\u0627 \n\u0627\u0644\u0627\u0633\u062a\u0645\u0627\u0631\u0647 \u063a\u064a\u0631 \u0642\u0627\u0628\u0644\u0647 \u0644\u0644\u062d\u0643 \u0648 \u0627\u0644\u0634\u0637\u0628 \u0648 \u0627\u0644\u062a\u0639\u062f\u064a\u0644',
			created_at: '2024-05-02T15:08:01.000000Z',
			updated_at: '2024-05-02T20:39:08.000000Z',
		},
	]

	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) {
		window.location.href = '/'
	}

	const fetchData = async () => {
		setLoading(true)

		let result = null
		try {
			result = await axios({
				url: url + `/classes`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + userData?.token,
				},
				params: {
					load: 'b',
				},
			})

			setUpdateVehicleClasse(result.data.Data)
			loadDepartmentVehicleClass()
		} catch (error) {
			let code = getStatusCode(error)
			let errCodeObj = handleErr(code, error)
			setAlert(errCodeObj)
			setLoading(false)
		}
	}
	const loadDepartmentVehicleClass = async () => {
		setLoading(true)

		let result = null
		try {
			result = await axios({
				url: url + `/departments_vehicle_classes`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + userData?.token,
				},
				params: {
					id: props?.object?.id,
				},
			})
			console.log('sdfsdfsdf===>', result?.data)
			setDepartmentVehicleClasses(result.data)
			setLoading(false)
		} catch (error) {
			let code = getStatusCode(error)
			let errCodeObj = handleErr(code, error)
			setAlert(errCodeObj)
			setLoading(false)
		}
	}
	useEffect(() => {
		fetchData()
	}, [props?.object])

	function closeSnackbar(event, reason) {
		if (reason === 'clickaway') {
			return
		}
		setAlert({
			show: false,
			title: '',
			msg: '',
			color: 'error',
		})
	}
	const checkSelectedValue = (id) => {
		let check = false
		check = departmentVehicleClasses?.find((obj) => id == obj?.id)
		return check
	}
	const handleChange = (item) => {
		let check = departmentVehicleClasses?.find((obj) => item?.id == obj?.id)
		if (check) {
			setDepartmentVehicleClasses(
				departmentVehicleClasses?.filter((obj) => item?.id != obj?.id)
			)
		} else {
			setDepartmentVehicleClasses([...departmentVehicleClasses, item])
		}
	}
	const submit = async () => {
		try {
			if (!props?.object?.id) {
				setAlert({
					show: true,
					color: 'error',
					title: 'معرف القسم غير محدد',
					msg: 'معرف القسم غير محدد',
				})
				return
			}

           setLoading(true)
			const data = await axios({
				url: url + `/departments_assign_vehicle_classes`,
				method: 'post',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + userData?.token,
				},
				params: {
					id: props?.object?.id,
					ids: departmentVehicleClasses?.length?departmentVehicleClasses?.map((item) => item?.id):[],
				},
			})
			setLoading(false)
   
			if(data&&data?.status){
				setTimeout(()=>{
					props?.closeAddModal();
				},1000)
				setAlert({
					show: true,
					color: 'success',
					title: "تم اسناد البيانات بنجاح",
					msg: "تم اسناد البيانات بنجاح",
				})
			}else{
				setAlert({
					show: true,
					color: 'error',
					title: "غطء غير معروف",
					msg: "غطء غير معروف",
				})
			}
		} catch (err) {
			setLoading(false)
			let code = getStatusCode(err)
			let errCodeObj = handleErr(code, err)
			setAlert(errCodeObj)
			setLoading(false)
		}
	}
	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<Dialog
					fullWidth={true}
					maxWidth="100%"
					maxHeight="100%"
					disableBackdropClick={true}
					open={props.open}
					onClose={props.closeAddModal}
					PaperComponent={PaperComponent}
					aria-labelledby="draggable-dialog-title"
					onKeyDown={handleEnteredEvent}
					TransitionComponent={Transition}
					sx={{}}
				>
					<DialogTitle
						style={{
							backgroundColor: '#007AC4',
							color: '#fff',
							cursor: 'move',
						}}
						id="draggable-dialog-title"
						className={classes.appBar}
					>
						واجهة اسناد اصناف لـــ {props?.object?.name}
					</DialogTitle>
					<DialogContent
						style={{
							backgroundColor: '#DEF3FF',
						}}
					>
						{loading ? (
							<Loading />
						) : (
							<Root className={classes.Root}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										flexWrap: 'wrap',
										gap: '10px',
										paddingTop: '20px',
									}}
								>
									{vehicleClasses?.length &&
										vehicleClasses?.map((item) => (
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between',
													flexWrap: 'wrap',
													gap: '10px',
													backgroundColor: checkSelectedValue(item?.id)
														? '#d4e0e1'
														: '#f3f3f3',
													padding: '10px 20px',
													minWidth: '200px',
													cursor: 'pointer',
												}}
												key={item?.id}
												onClick={() => handleChange(item)}
											>
												<span>{item?.name}</span>
												{!checkSelectedValue(item?.id) ? (
													<RadioButtonUncheckedIcon />
												) : (
													<RadioButtonCheckedIcon style={{ fill: '#ff0000' }} />
												)}
											</div>
										))}
								</div>
							</Root>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							disabled={loading}
							autoFocus
							onClick={() => {
								setLoading(false)
								setDepartmentVehicleClasses([])
								props.closeAddModal()
							}}
							color="primary"
						>
							الغاء
						</Button>
						<Button disabled={loading} onClick={submit} color="primary">
							حفظ
						</Button>
					</DialogActions>
					{alert.show ? (
						<Snackbar
							title={alert.title}
							msg={alert.msg}
							show={alert.show}
							period={6000}
							severity={alert.color}
							closeSnackbar={closeSnackbar}
						></Snackbar>
					) : null}
				</Dialog>
			</StylesProvider>
		</ThemeProvider>
	)
}
