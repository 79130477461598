import React, { useState, useEffect } from 'react'
import Root from '../../common/BodyMRoot'
import CrudControl from '../../common/CrudControl'
import Loading from '../../common/Loading'
import axios from 'axios'
import Cookies from 'js-cookie'
import Snackbar from '../../common/SnackBar'
import {
	formatErrorMgs2,
	getBaseURL,
	getStatusCode,
	handleErr,
} from '../../common/Utils'
import { getGivenItems } from '../../common/ApiHelper'
import NoDataDiv from '../../common/NoDataDiv'
import CustomTable from './CustomTable'
import AddModal from './AddModal'
import YesNoDialogDelete from '../../common/YesNoDialog'
import EditModal from './EdtiModal'
import CustomPagination from '../../common/CustomPagination'
import { CircularProgress } from '@material-ui/core'
import BackDropLoading from '../../common/BackDropLoading'
const url = getBaseURL()

function VehicleClass(props) {
	const [rows, setRows] = useState([])
	const [filteredCenters, setFilteredCenters] = useState([])
	const [fieldsData, setFieldsData] = useState({
		name: '',
		descr: '',
		note_thatee:'',
		note_moajar:'',
		is_in:false,
		is_out:false,
	})
	const [search, setSearch] = useState('')
	const [departments, setDepartments] = useState([])

	const [bigError, setBigError] = useState(false)
	const [errCode, setErrCode] = useState({
		status: 0,
		title: '',
		server_errors: '',
		how_to_fix: '',
	})
	const [alert, setAlert] = useState({
		show: false,
		color: 'error',
		title: '',
		msg: '',
	})
	const [isLoading, setIsLoading] = useState(true)
	const [isRequesting, setIsRequesting] = useState(false)
	const [updateVehicleClasse, setUpdateVehicleClasse] = useState(false)
	const [pageSize, setPageSize] = useState(5)
	const [pageNumber, setPageNumber] = useState(1)
	const [totalRows, setTotalRows] = useState(0)
	const [pageCount, setPageCount] = useState(0)
	const [showAddModal, setShowAddModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [selectedData, setSelectedData] = useState(null)
	const [yesNoDialogDelete, setYesNoDialogDelete] = useState({
		open: false,
		title: '',
		msg: '',
	})
	let userData = null
	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	} catch (error) { 
		window.location.href = '/'
	}
	const fetchData = async () => {
		
		setIsLoading(true)
		setIsRequesting(true)

		let result = null
		try {
			result = await axios({
				url: url + `/get_vehicle_classes_paginate`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + userData?.token,
				},
				params: {
					page_size: pageSize,
					page: pageNumber,
					search: search ? search?.toLowerCase() : null,
				},
			})

			setRows(result.data.Data?.data)
			setFilteredCenters(result.data.Data?.data)
			setPageCount(result.data.Data?.last_page)
			setTotalRows(result.data.Data?.total)
			console.log(result.data.Data)
			setIsLoading(false)
			setIsRequesting(false)
		} catch (error) {
			let code = getStatusCode(error)
			let errCodeObj = handleErr(code, error)
			setErrCode(errCodeObj)

			setIsRequesting(false)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		// fetchData()
	}, [updateVehicleClasse])
	useEffect(() => {
		fetchData()
	}, [pageNumber, pageSize])
	return (
		<Root>
			{isLoading ? <BackDropLoading open={isLoading} /> : null}
			<CrudControl
				showSearch={true}
				openModal={openAddModal}
				onSearchChange={onSearchChange}
				onSearchEnter={onSearchEnter}
				searchValue={search}
			/>
			{!isLoading && rows.length === 0 ? (
				<NoDataDiv />
			) : (
				<>
					<CustomTable
						data={rows}
						showEditModalFun={showEditModalFun}
						showDeleteModalFun={showDeleteModalFun}
					></CustomTable>
					<CustomPagination
						count={pageCount}
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						pageSize={pageSize}
						setPageSize={setPageSize}
					/>
					<br />
					<br />
					<br />
				</>
			)}

			{showAddModal ? (
				<AddModal
					closeAddModal={closeAddModal}
					open={showAddModal}
					changeField={changeField}
					fieldsData={fieldsData}
					isRequesting={isRequesting}
					changeChecks={changeChecks}
					save={save}
				/>
			) : null}
			{showEditModal ? (
				<EditModal
					closeEditModal={closeEditModal}
					open={showEditModal}
					changeField={changeField}
					fieldsData={fieldsData}
					isRequesting={isRequesting}
					changeChecks={changeChecks}
					edit={edit}
				/>
			) : null}
			{alert.show ? (
				<Snackbar
					title={alert.title}
					msg={alert.msg}
					show={alert.show}
					period={6000}
					severity={alert.color}
					closeSnackbar={closeSnackbar}
				></Snackbar>
			) : null}
			{yesNoDialogDelete.open ? (
				<YesNoDialogDelete
					open={yesNoDialogDelete.open}
					title={yesNoDialogDelete.title}
					msg={yesNoDialogDelete.msg}
					onYes={onYes}
					cancel={cancel}
					isRequesting={isRequesting}
				/>
			) : null}
		</Root>
	)
	function edit(e) {
		let errors = validate()
		if (errors.length > 0) {
			setAlert({
				show: true,
				color: 'error',
				title: 'خطأ في المدخلات',
				msg: formatErrorMgs2(errors),
			})
			return
		}
		let data = {
			name: fieldsData.name,
			description: fieldsData.descr, //we gonna use the descr field in DB for the department manager instead.
			note_moajar:fieldsData?.note_moajar,
			note_thatee:fieldsData?.note_thatee,
			is_in: fieldsData.is_in,
			is_out: fieldsData.is_out,
			_method: 'PUT',
		}
		editRequest(data)
	}
	function editRequest(data) {
		if (!selectedData?.vehicleClass?.id) {
			setAlert({
				show: true,
				color: 'error',
				title: 'خطأ في المدخلات',
				msg: 'يجب اختيار الصنف',
			})
			return
		}
		setIsLoading(true)
		setIsRequesting(true)
		axios({
			url: url + `/classes/` + selectedData?.vehicleClass?.id,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				console.log('test response')
				setIsLoading(false)
				setIsRequesting(false)
				setSelectedData(null)
				setAlert({
					show: true,
					color: 'success',
					title: 'تم التعديل بنجاح',
					msg: 'لقد تم تعديل الصنف بنجاح',
				})
				setShowEditModal(false)
				setSearch('')
				fetchData()
			})
			.catch((error) => {
				console.log('test catch')
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setAlert({
					show: true,
					color: 'error',
					title: 'هنالك خطأ ما',
					msg: errCodeObj.how_to_fix,
				})
				setIsLoading(false)
				setIsRequesting(false)
			})
	}
	function closeEditModal(e) {
		setSelectedData(null)
		setShowEditModal(false)
	}
	function onYes(e) {
		if (selectedData) {
			deleteRequest(selectedData?.vehicleClass?.id)
		}
	}
	function cancel(e) {
		setSelectedData(null)
		setYesNoDialogDelete({
			open: false,
			title: '',
			msg: '',
		})
	}
	function closeSnackbar(event, reason) {
		if (reason === 'clickaway') {
			return
		}
		setAlert({
			show: false,
			title: '',
			msg: '',
			color: 'error',
		})
		setErrCode({
			status: 0,
			title: '',
			server_errors: [],
			how_to_fix: '',
		})
	}
	function changeChecks(e,number){
		console.log('asdadasdasdas===>',e?.target)
      if(number==1){
		setFieldsData({
			...fieldsData,
			is_in: e?.target?.checked,
		})
	  }else{
		setFieldsData({
			...fieldsData,
			is_out: e?.target?.checked,
		})
	  }
	}

	function onDepartmentChange(e, value) {
		setFieldsData({
			...fieldsData,
			selectedDepartment: value,
		})
	}
	function save(e) {
		let errors = validate()
		if (errors.length > 0) {
			setAlert({
				show: true,
				color: 'warning',
				msg: formatErrorMgs2(errors),
				title: 'تأكد من المدخلات',
			})
			return
		}
		let data = {
			name: fieldsData.name,
			is_in: fieldsData.is_in,
			is_out: fieldsData.is_out,
		}
		if (fieldsData.descr && fieldsData.descr.length > 0) {
			data = {
				...data,
				description: fieldsData.descr,
			}
		}
		if (fieldsData.note_thatee && fieldsData.note_thatee.length > 0) {
			data = {
				...data,
				note_thatee: fieldsData.note_thatee, 
			}
		}if (fieldsData.note_moajar && fieldsData.note_moajar.length > 0) {
			data = {
				...data,
				note_moajar: fieldsData.note_moajar,
			}
		}
		saveRequest(data)
	}

	function validate() {
		let errors = []
		if (!fieldsData.name || fieldsData.name.length < 4) {
			errors.push('يجب ان لا يقل اسم الصنف عن 3 حروف')
		}
		return errors
	}

	function saveRequest(data) {
		setIsLoading(true)
		setIsRequesting(true)
		axios({
			url: url + `/classes`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
		})
			.then((response) => {
				let msg = 'تم اضافة الصنف بنجاح'
				setUpdateVehicleClasse(!updateVehicleClasse)
				setFieldsData({
					name: '',
					descr: '',
					note_moajar:'',
					note_thatee:''
				})
				setShowAddModal(false)
				setSearch('')
				setIsLoading(false)
				setIsRequesting(false)
				setAlert({
					show: true,
					color: 'success',
					title: msg,
					msg: msg,
				})
				fetchData()
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setAlert({
					show: true,
					color: 'error',
					title: 'هنالك خطأ ما',
					msg: errCodeObj.how_to_fix,
				})
				setIsLoading(false)
				setIsRequesting(false)
			})
	}
	function changeField(e) {
		setFieldsData({
			...fieldsData,
			[e.target.name]: e.target.value,
		})
	}
	function closeAddModal(e) {
		setShowAddModal(false)
	}
	function openAddModal(e) {
		setShowAddModal(true)
		setFieldsData({
			name: '',
			descr: '',
			note_thatee:'',
			note_moajar:'',
			is_in:false,
			is_out:false,
		})
	}

	function onSearchChange(e) {
		setSearch(e.target.value)
	}
	function onSearchEnter(e) {
		if (e.key === 'Enter') {
			if (pageNumber == 1) fetchData()
			else setPageNumber(2)
		}
	}
	function showDeleteModalFun(centerObj) {
		setSelectedData(centerObj)
		setYesNoDialogDelete({
			open: true,
			title: 'حذف الصنف',
			msg: 'يرجى الانتباه هنا. عند حذف هذا الصنف، سيتم حذف ارتباطه بالمركبات التي تمت إضافتها إليه. وبالتالي، قد يحدث خلل في بعض البيانات.',
		})
	}
	function showEditModalFun(centerObj) {
		setSelectedData(centerObj)
		setFieldsData({
			name: centerObj.vehicleClass.name,
			descr: centerObj.vehicleClass.descr,
			note_thatee: centerObj?.vehicleClass?.note_thatee,
			note_moajar: centerObj?.vehicleClass?.note_moajar,
			is_in: centerObj?.vehicleClass?.is_in,
			is_out: centerObj?.vehicleClass?.is_out,
		})
		setShowEditModal(true)
	}
	function deleteRequest(id) {
		setIsLoading(true)
		setIsRequesting(true)
		axios({
			url: url + `/classes/` + id,
			method: 'delete',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
		})
			.then((response) => {
				let msg = 'تم حذف الصنف بنجاح'
				setUpdateVehicleClasse(!updateVehicleClasse)
				setFieldsData({
					name: '',
					descr: '',
					note_moajar:'',
					note_thatee:''
				})
				setShowAddModal(false)
				setSearch('')
				setIsLoading(false)
				setIsRequesting(false)
				setAlert({
					show: true,
					color: 'success',
					title: msg,
					msg: msg,
				})
				setYesNoDialogDelete({
					open: false,
					title: '',
					msg: '',
				})
				if (pageNumber == pageCount) {
					let number = Math.ceil((totalRows - 1) / pageSize)
					setPageNumber(number)
				} else {
					fetchData()
				}
			})
			.catch((error) => {
				let code = getStatusCode(error)
				let errCodeObj = handleErr(code, error)
				setAlert({
					show: true,
					color: 'error',
					title: 'هنالك خطأ ما',
					msg: errCodeObj.how_to_fix,
				})
				setIsLoading(false)
				setIsRequesting(false)
			})
	}
}

export default VehicleClass
