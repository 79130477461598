import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CollapseItem from './CollapseItem'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import {
	getBaseURL,
	handleError,
	handleErr,
	getStatusCode,
} from '../../common/Utils'
import Cookies from 'js-cookie'
import {
	Box,
	Button,
	CircularProgress,
	IconButton,
} from '@material-ui/core'
import Snackbar from '../../common/SnackBar'

import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SaveIcon from '@material-ui/icons/Save'

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
}))
const url = getBaseURL()

export default function NameOfOfficials() {
	const [loading, setLoading] = useState(false)
	const userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
	if (
		userData.userInfo.role.name !== 'Admin' &&
		userData.userInfo.role.name !== 'Root'
	)
		window.location.href = '/'
	const classes = useStyles()
	const [reportsData, setReportsData] = useState([])
	const [departments, setDepartments] = useState([])
	const [alertError, setAlertError] = useState(false)
	const [alertErrorMsg, setAlertErrorMsg] = useState([])
	const [alertColor, setAlertColor] = useState('error')
	const [alert, setAlert] = useState({
		show: false,
		color: 'error',
		title: '',
		msg: '',
	})
	const getData = async () => {
		setLoading(true)
		try {
			let data = await axios({
				method: 'get',
				url: url + '/reports',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + userData.token,
				},
			})
			setLoading(false)
			if (data && data?.status) {
				if (data?.data?.Data) {
					setReportsData(data?.data?.Data)
					getDataOfDepartments()
				}
			}
		} catch (err) {
			setLoading(false)
		}
	}
	const getDataOfDepartments = async () => {
		setLoading(true)
		try {
			let data = await axios({
				method: 'get',
				url: url + '/departments',
				params: {
					load: 'b',
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + userData.token,
				},
			})
			setLoading(false)
			if (data && data?.status) {
				if (data?.data?.Data) {
					setDepartments(data?.data?.Data)
				}
			}
		} catch (err) {
			setLoading(false)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	const handleChnage = (value, name, id, signId) => {
		let arr = []
		if (reportsData && reportsData?.length > 0)
			reportsData.map((itm) => {
				if (itm?.id == id) {
					let subArr = []
					if (itm?.params && itm?.params?.signatures?.length > 0) {
						itm.params.signatures.map((list, index) => {
							if (index == signId) {
								subArr.push({
									...list,
									[name]: value,
								})
							} else {
								subArr.push(list)
							}
						})
					}
					arr.push({
						...itm,
						params: {
							...itm?.params,
							signatures: subArr,
						},
					})
				} else {
					arr.push(itm)
				}
			})
		if (arr && arr?.length > 0) setReportsData(arr)
	}

	const handleChnage2 = (value, name, id, signId, depID) => {
		let arr = []
		if (reportsData && reportsData?.length > 0)
			reportsData.map((itm) => {
				if (itm?.id == id) {
					let subArr = []
					if (itm?.params && itm?.params?.signatures?.length > 0) {
						itm.params.signatures.map((list, index) => {
							if (index == signId) {
								let subSubArr = []
								if (list && list?.departments?.length > 0) {
									list.departments &&
										list.departments.map((dep, depIndex) => {
											if (depID == depIndex) {
												subSubArr.push({
													...dep,
													[name]: value,
												})
											} else {
												subSubArr.push(dep)
											}
										})
								}
								subArr.push({
									...list,
									departments: subSubArr,
								})
							} else {
								subArr.push(list)
							}
						})
					}
					arr.push({
						...itm,
						params: {
							...itm?.params,
							signatures: subArr,
						},
					})
				} else {
					arr.push(itm)
				}
			})
		if (arr && arr?.length > 0) setReportsData(arr)
	}

	const getDepartments = (ids) => {
		let selected = []
		departments &&
			departments?.length > 0 &&
			departments.map((itm) => {
				if (ids && ids?.includes(itm?.id)) {
					selected.push(itm)
				}
			})
		return selected
	}
	const submit = async () => {
		if (!reportsData) {
			return
		}
		try {
			setLoading(true)
			let data = await axios({
				url: url + '/updateAllReportParams',
				method: 'post',
				data: {
					data: reportsData,
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + userData.token,
				},
			})
			setLoading(false)
			console.log('asdasdasdasdasd', data?.data?.Code)
			if (data && data?.data?.Code == 200) {
				console.log('asdasdasdasdasd===>enterd', data?.data?.Code)

				setAlertColor('success')
				setAlertError(true)
				setAlertErrorMsg(['تم تحديث البيانات بنجاح'])
				setAlert({
					show: true,
					title: 'تم التحديث بنجاح',
					msg: 'تم تحديث البيانات بنجاح',
					color: 'success',
				})
			} else {
				if (data?.data?.Code == 400) {
					setAlertColor('error')
					setAlertError(true)
					let message = 'خطء غير معروف'
					if (data?.data?.Data && typeof data?.data?.Data == 'string')
						message = data?.data?.Data
					setAlert({
						show: true,
						title: message,
						msg: message,
						color: 'error',
					})
				}
			}
		} catch (err) {
			setLoading(false)
			console.log(err?.message)
		}
	}
	function closeSnackbar(event, reason) {
		if (reason === 'clickaway') {
			return
		}
		setAlertError(false)
		setAlert({
			show: false,
			title: '',
			msg: '',
			color: 'error',
		})
	}
	useEffect(() => {
		let arr = []
		if (reportsData && departments) {
			reportsData?.length > 0 &&
				reportsData.map((itm) => {
					if ([8,9,12,14]?.includes(itm?.id)) {
						if (itm?.params) {
							if (itm?.params?.signatures) {
								let subArr = []
								itm.params.signatures.map((sig) => {
									if (sig?.departments) {
										let subSubArr = []
										departments?.length > 0 &&
											departments.map((dep) => {
												const selecteDepart = sig?.departments?.find((itm) =>
													itm?.ids?.includes(dep?.id)
												)
												if (selecteDepart) {
													subSubArr.push(selecteDepart)
												} else {
													subSubArr.push({
														ids: [dep?.id],
														name: '',
														position: '',
													})
												}
											})
										subArr.push({
											...sig,
											departments: subSubArr,
										})
									} else {
										subArr.push(sig)
									}
								})
								arr.push({
									...itm,
									params: {
										...itm?.params,
										signatures: subArr,
									},
								})
							} else {
								arr.push(itm)
							}
						} else {
							arr.push(itm)
						}
					} else {
						arr.push(itm)
					}
				})
			setReportsData(arr)
		}
	}, [departments])
	useEffect(() => {
		console.log('sdsdsdfsdf====>')
		document.addEventListener('keydown', handleEnteredEvent)
		return () => {
			document.removeEventListener('keydown', handleEnteredEvent)
		}
	})
	const handleEnteredEvent = (e) => {
		if (e.key === 'Enter') {
			submit()
		} else if (e.key === 'Escape') {
			// props.hideModal()
		}
	}
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: loading ? 'center' : 'flex-start',
				alignItems: 'center',
				width: '100%',
				height: '100%',
				flexDirection: 'column',
				gap: '10px',
				padding: '20px 0 60px 0',
				marginBottom: '30px',
			}}
		>
			{alert.show ? (
				<Snackbar
					title={alert.title}
					msg={alert.msg}
					show={alert.show}
					period={6000}
					severity={alert.color}
					closeSnackbar={closeSnackbar}
				></Snackbar>
			) : null}
			{loading ? (
				<CircularProgress />
			) : (
				<Box
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
						margin: '10px 0',
					}}
				>
					<Button
						variant="contained"
						color="primary"
						size="large"
						startIcon={<SaveIcon style={{ margin: '0 10px' }} />}
						style={{
							direction: 'rtl',
							backgroundColor: '#007AC4',
						}}
						onClick={() => {
							submit()
						}}
					>
						حفظ
					</Button>
				</Box>
			)}
			{!loading &&
				reportsData &&
				reportsData?.length > 0 &&
				reportsData?.map((item) =>
					item?.id && [8, 9, 12, 14]?.includes(item?.id) ? (
						<CollapseItem title={item?.caption} item={item}>
							<Box
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									flexWrap: 'wrap',
									gap: '10px',
								}}
							>
								{item?.params &&
									item?.params?.signatures?.length > 0 &&
									item?.params?.signatures?.map((itm, index) =>
										itm?.departments && itm?.departments?.length > 0 ? (
											itm?.departments?.map((list, subIndex) => (
												<Box
													style={{
														border: '2px solid #dedddd',
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
														padding: '10px',
														gap: '10px',
														borderRadius: '5px',
													}}
												>
													<span style={{ fontWeight: 'bold' }}>
														{index + 1 + '.' + (subIndex + 1)}
													</span>
													<TextField
														id="outlined-basic"
														label={'العنوان'}
														variant="outlined"
														value={list?.position}
														onChange={(e) => {
															handleChnage2(
																e?.target?.value,
																'position',
																item?.id,
																index,
																subIndex
															)
														}}
													/>
													<TextField
														id="outlined-basic"
														label={'الاسم'}
														variant="outlined"
														value={list?.name}
														onChange={(e) => {
															handleChnage2(
																e?.target?.value,
																'name',
																item?.id,
																index,
																subIndex
															)
														}}
													/>
													<Autocomplete
														dir="rtl"
														multiple
														id="fixed-tags-demo"
														value={getDepartments(list?.ids)}
														onChange={(event, newValue) => {
															handleChnage2(
																newValue && newValue?.length > 0
																	? newValue.map((itm) => itm?.id)
																	: [],
																'ids',
																item?.id,
																index,
																subIndex
															)
														}}
														// limitTags={1}
														options={departments}
														getOptionLabel={(option) => option.name}
														renderTags={(tagValue, getTagProps) =>
															tagValue.map((option, index) => (
																<Chip
																	label={option?.name}
																	{...getTagProps({ index })}
																/>
															))
														}
														disabled
														style={{ width: 500 }}
														renderInput={(params) => (
															<TextField
																{...params}
																label="القسم"
																variant="outlined"
																placeholder="Favorites"
															/>
														)}
													/>
												</Box>
											))
										) : item?.id != 12 && item?.id != 14 && (item?.id == 9 || index > 1) ? (
											<Box
												style={{
													border: '2px solid #dedddd',
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
													padding: '10px',
													gap: '10px',
													borderRadius: '5px',
												}}
											>
												<span style={{ fontWeight: 'bold' }}>
													{index + 1 + ' '}
												</span>
												<TextField
													id="outlined-basic"
													label={'العنوان'}
													variant="outlined"
													value={itm?.position}
													onChange={(e) => {
														handleChnage(
															e?.target?.value,
															'position',
															item?.id,
															index
														)
													}}
												/>
												<TextField
													id="outlined-basic"
													label={'الاسم'}
													variant="outlined"
													value={itm?.name}
													onChange={(e) => {
														handleChnage(
															e?.target?.value,
															'name',
															item?.id,
															index
														)
													}}
												/>
											</Box>
										) : (item?.id == 12 || item?.id == 14) &&
											index != 1 &&
											index != 4 ? (
											<Box
												style={{
													border: '2px solid #dedddd',
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
													padding: '10px',
													gap: '10px',
													borderRadius: '5px',
												}}
											>
												<span style={{ fontWeight: 'bold' }}>
													{index + 1 + ' '}
												</span>
												<TextField
													id="outlined-basic"
													label={'العنوان'}
													variant="outlined"
													value={itm?.position}
													onChange={(e) => {
														handleChnage(
															e?.target?.value,
															'position',
															item?.id,
															index
														)
													}}
												/>
												<TextField
													id="outlined-basic"
													label={'الاسم'}
													variant="outlined"
													value={itm?.name}
													onChange={(e) => {
														handleChnage(
															e?.target?.value,
															'name',
															item?.id,
															index
														)
													}}
												/>
											</Box>
										) : null
									)}
							</Box>
						</CollapseItem>
					) : null
				)}
			<br />
			<br />
			<br />
		</div>
	)
}
