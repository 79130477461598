import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import { Fragment } from 'react'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		minHeight: '35px',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-evenly',
		},
	},
	lastActivity: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
}))
function UserStatus(props) {
	const classes = useStyles()
	return (
		<Paper className={classes.root}>
			<Grid container spacing={0}>
				<Grid item xs={12} md={3}>
					<div>
						{props.isLoading ? (
							<Skeleton variant="rect" />
						) : (
							<p
								style={{
									marginLeft: '5px',
									marginRight: '5px',
								}}
							>
								{props.data.id}
							</p>
						)}
					</div>
				</Grid>
				<Grid item xs={12} md={3}>
					<div>
						{props.isLoading ? (
							<Skeleton variant="rect" />
						) : (
							<p>{props.data.name}</p>
						)}
					</div>
				</Grid>
				<Grid item xs={12} md={3} >
					<div className={classes.lastActivity} style={{
					backgroundColor:'#ffa202'
				}}>
						{props.isLoading ? (
							<Skeleton variant="rect" />
						) : (
							<Fragment>
								<p>
									{props.data.last_activity
										? props.data.last_activity.last_visit_at
										: '----'}
								</p>
								<p>
									{props.data.last_activity
										? props.data.last_activity.last_visit_ago
										: '----'}
								</p>
							</Fragment>
						)}
					</div>
				</Grid>
				<Grid item xs={12} md={3} >
					<div className={classes.lastActivity} style={{
					backgroundColor:'#04d115'
				}}>
						{props.isLoading ? (
							<Skeleton variant="rect" />
						) : (
							<Fragment>
								<p>
									{props.data.last_activity
										? props.data.last_activity.last_action_at
										: '----'}
								</p>
								<p>
									{props.data.last_activity
										? props.data.last_activity.last_action_ago
										: '----'}
								</p>
							</Fragment>
						)}
					</div>
				</Grid>
			</Grid>
		</Paper>
	)
}

export default UserStatus
