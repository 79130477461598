import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
	formatErrorMgs2,
	configureDate,
	getBaseURL,
	getStatusCode,
	handleErr,
	configureMonth,
} from '../../common/Utils'
import Root from '../../common/BodyMRoot'
import axios from 'axios'
import Cookies from 'js-cookie'
import Snackbar from '../../common/SnackBar'
import { getGivenItems } from '../../common/ApiHelper'
import Loading from '../../common/Loading'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Checkbox from '../../common/CheckBox'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import DayDate from '../../common/DatePicker'
import FromPicker from '../../common/DatePicker'
import ToPicker from '../../common/DatePicker'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

let moment = require('moment')
const url = getBaseURL()

const report8OptionsList =
	require('../../../assets/files/lists.json').report8Options

const rep1_templates =
	require('../../../assets/files/lists.json').rep1_templates
const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #def3ff;
`

const FormBox = styled.div`
	padding: 10px;
	width: 500px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 10px;
	-webkit-box-shadow: 0px 6px 13px -4px rgba(0, 0, 0, 0.52);
	-moz-box-shadow: 0px 6px 13px -4px rgba(0, 0, 0, 0.52);
	box-shadow: 0px 6px 13px -4px rgba(0, 0, 0, 0.52);
`

const RowDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: baseline;
`
const useStyles = makeStyles((theme) => ({
	textField: {
		width: '100%',
		marginTop: '10px',
	},
	autocomplete: {
		marginTop: '15px',
		marginRight: '15px',
	},
	checkbox: {
		marginTop: '25px',
		paddingTop: '25px',
		marginLeft: '5px',
	},
	bg: {
		marginTop: '15px',
		marginRight: '5px',
	},
}))
function Reports(props) {
	const [reports, setReports] = useState([])
	const [selectedReport, setSelectedReport] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [isRequesting, setIsRequesting] = useState(false)
	const [bigError, setBigError] = useState(false)
	const [departments, setDepartments] = useState([])
	const [selectedDepartment, setSelectedDepartment] = useState(null)
	const [vClasses, setVClasses] = useState([])
	const [selectedVClass, setSelectedVClass] = useState(null)
	const [shifts, setShifts] = useState([])
	const [isAllShifts, setIsAllShifts] = useState(false)
	const [selectedShift, setSelectedShift] = useState(null)
	const [selectedShifts, setSelectedShifts] = useState([])
	const [vehiclesTypes, setVehiclesTypes] = useState([])
	const [selectedVehicleType, setSelectedVehicleType] = useState(null)
	const [reasons, setReasons] = useState([])
	const [selectedReason, setSelectedReason] = useState(null)
	const [selectedStation, setSelectedStation] = useState(null)
	const [selectedMonth, setSelectedMonth] = useState(null)
	const [stations, setStations] = useState([])
	const [intervalType, setIntervalType] = useState('day')
	const [selectedDayDate, setSelectedDayDate] = useState(new Date())
	const [selectedFrom, setSelectedFrom] = useState(new Date())
	const [selectedTo, setSelectedTo] = useState(new Date())
	const [drivers, setDrivers] = useState([])
	const [isAllTargets, setIsOnTargets] = useState(false)
	const [isAllClasses, setIsAllClasses] = useState(false)
	const [isAllReasons, setIsAllReasons] = useState(false)
	const [isAllDepartments, setIsAllDepartments] = useState(false)

	const [stationType, setStationType] = useState('all') // all, some, specific
	const [departmentType, setDepartmentType] = useState('specific') // all, some, specific
	const [reasonType, setReasonType] = useState('all') // all, some, specific
	const [centerType, setCenterType] = useState('all') // default value, other value is "specific"
	const [showClassChoice, setShowClassChoice] = useState(false)
	const [showVTChoice, setShowVTChoice] = useState(true)
	const [showDepartments, setShowDepartments] = useState(true)
	const [showStations, setShowStations] = useState(false)
	const [showShifts, setShowShifts] = useState(true)
	const [showReasons, setShowReasons] = useState(false)
	const [showStationCheck, setShowStationCheck] = useState(false)
	const [showDepartmentCheck, setShowDepartmentCheck] = useState(false)

	const [showReasonCheck, setShowReasonCheck] = useState(false)
	const [showClassCheck, setshowClassCheck] = useState(true)
	const [targetVehicles, setTargetVehicles] = useState('1') // thatee
	const [params, setParams] = useState(null)
	const [selectedCenter, setSelectedCenter] = useState(null)
	const [centers, setCenters] = useState([])
	const [showCenters, setShowCenters] = useState(false)
	const [showCenterCheck, setShowCenterCheck] = useState(false)
	const [showRep1TemplateDiv, setShowRep1TemplateDive] = useState(true) // default according to default value of first report kabesat
	const [selectedTemplate, setSelectedTemplate] = useState(rep1_templates[0])
	const [boolean1, setboolean1] = useState(false)
	const [boolean2, setboolean2] = useState(false)
	const [boolean3, setboolean3] = useState(false)
	const [boolean4, setboolean4] = useState(false)
	const [selectedDepartmentDemo, setselectedDepartmentDemo] = useState(false)
	// centers here
	const [showselectedCenters, setshowselectedCenters] = useState(false)
	const [showselectedCentersAll, setshowselectedCentersAll] = useState(true)

	const CancelToken = axios.CancelToken
	var cancelReq
	const cancelSource = useRef(null)

	const [alert, setAlert] = useState({
		show: false,
		color: 'error',
		title: '',
		msg: '',
	})

	// const [report8Options, setReport8Options] = useState(report8OptionsList)
	const [selectedReport8Option, setSelectedReport8Option] = useState(
		report8OptionsList[0]
	)
	const [showReport8Options, setShowReport8Options] = useState(false)

	const [showCheckOfVT, setShowCheckOfVt] = useState(true)
	let userData = null

	try {
		userData = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
		if (userData.userInfo.role.name === 'Data Entry') {
			window.location.href = '/'
		}
	} catch (error) {}

	useEffect(() => {
		cancelSource.current = CancelToken.source()

		const fetchResources = async () => {
			let result = await getGivenItems([
				'reports',
				'departments',
				'classes',
				'vehicleTypes',
				'shifts',
				'reasons',
				'stations',
			])
			if (!result || result.length === 0) {
				window.location.href = '/'
				props.history.push('/login')
				Cookies.remove('SDIMS_USER_DATA')
			} else {
				if (!result.data && !result.data.Data) {
					setBigError(true)
					return
				} else {
					let arrays = result.data.Data.returned_items
					setReports(arrays.reports)
					setSelectedReport(arrays.reports[0])
					setDepartments(arrays.departments ? arrays.departments : [])
					setSelectedDepartment(
						arrays.departments ? arrays.departments[0] : null
					)
					setselectedDepartmentDemo(
						arrays.departments ? arrays.departments[0] : null
					)
					setCenters(
						arrays.selectedDepartment
							? arrays.selectedDepartment.centers
								? arrays.selectedDepartment.centers
								: []
							: []
					)
					setSelectedCenter(
						arrays.departments
							? arrays.departments[0].centers
								? arrays.departments[0].centers[0]
								: null
							: null
					)

					setVClasses(arrays.classes)
					setSelectedVClass(arrays.classes[0])
					setVehiclesTypes(arrays.vehicleTypes)
					setSelectedVehicleType(arrays.vehicleTypes[0])
					setShifts(arrays.shifts)
					setSelectedShift(arrays.shifts[0])
					setReasons(arrays.reasons)
					setSelectedReason(arrays.reasons[0])
					setStations(arrays.stations)
					setSelectedStation(arrays.stations ? arrays.stations[0] : null)
					setIsLoading(false)
				}
			}

			setIsLoading(false)
		}

		fetchResources()
	}, [])
	useEffect(() => {
		console.log('sdsdsdfsdf====>')
		document.addEventListener('keydown', handleEnteredEvent)
		return () => {
			document.removeEventListener('keydown', handleEnteredEvent)
		}
	})
	const handleEnteredEvent = (e) => {
		if (e.key === 'Enter') {
			startExecute()
		} else if (e.key === 'Escape') {
			cancelSource.current.cancel()
			cancelSource.current = CancelToken.source()
		}
	}

	useEffect(() => {
		if (selectedReport && (boolean1 || boolean2 || boolean3 || boolean4)) {
			startExecute()
		}
	}, [selectedFrom, selectedTo, selectedDayDate])

	const loadDepartmentVehicleClass = async () => {
		let result = null
		try {
			result = await axios({
				url: url + `/departments_vehicle_classes`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + userData?.token,
				},
				params: {
					...(selectedDepartmentDemo?.id
						? {
								id: selectedDepartmentDemo?.id,
						  }
						: {}),
				},
			})
			console.log('sdfsdfsdf===>', result?.data)
			setVClasses(result.data)
			if (result.data?.length) setSelectedVClass(result.data[0])
		} catch (error) {
			let code = getStatusCode(error)
			let errCodeObj = handleErr(code, error)
			setAlert(errCodeObj)
		}
	}
	useEffect(() => {
		setSelectedVClass(null)
		loadDepartmentVehicleClass()
	}, [selectedDepartmentDemo])

	// useEffect(()=>{
	//   if(departmentType=="all"){
	//      setselectedDepartmentDemo(null)
	//   }
	// },[departmentType])
	const classes = useStyles()
	return (
		<Root>
			<Container>
				{bigError ? (
					<p>هنالك خطأ ما</p>
				) : isLoading ? (
					<>
						<Loading />
						<Button
							style={{ marginTop: 200, marginRight: 50 }}
							disabled={false}
							onClick={() => {
								cancelSource.current.cancel()
								cancelSource.current = CancelToken.source()
							}}
							variant="contained"
							color="primary"
						>
							الغاء
						</Button>
					</>
				) : (
					<FormBox>
						<Autocomplete
							size="small"
							className={classes.autocomplete}
							id="reports"
							value={selectedReport ? selectedReport : reports[0]}
							options={reports}
							onChange={onReportChange}
							getOptionLabel={(option) => option.caption}
							style={{ width: 300 }}
							renderInput={(params) => (
								<TextField {...params} label="التقارير" variant="outlined" />
							)}
						/>
						{showRep1TemplateDiv && intervalType == 'interval' ? (
							<Autocomplete
								size="small"
								className={classes.autocomplete}
								id="rep1_templates"
								value={selectedTemplate ? selectedTemplate : rep1_templates[0]}
								options={rep1_templates}
								onChange={onTemplateChange}
								getOptionLabel={(option) => option.caption}
								style={{ width: 300 }}
								renderInput={(params) => (
									<TextField {...params} label="التقارير" variant="outlined" />
								)}
							/>
						) : null}
						{showStationCheck ? (
							<Checkbox
								size="small"
								className={classes.checkbox}
								name="all_shifts_or_one"
								label="جميع المواقع"
								state={stationType === 'all' ? true : false}
								changeState={onIsAllStationChange}
							/>
						) : null}
						{showStations ? (
							stationType === 'all' ? null : (
								<Autocomplete
									size="small"
									className={classes.autocomplete}
									id="stations"
									value={selectedStation ? selectedStation : stations[0]}
									options={stations}
									onChange={onStationChange}
									getOptionLabel={(option) => option.caption}
									style={{ width: 300 }}
									renderInput={(params) => (
										<TextField {...params} label="المواقع" variant="outlined" />
									)}
								/>
							)
						) : null}
						{showDepartmentCheck ? (
							<Checkbox
								size="small"
								className={classes.checkbox}
								name="show_departments_check"
								label="جميع الاقسام؟"
								state={departmentType == 'all' ? true : false}
								changeState={onIsAllDepartmentChange}
							/>
						) : null}
						{showDepartments ? (
							departmentType == 'all' ? null : (
								<React.Fragment>
									<Autocomplete
										size="small"
										className={classes.autocomplete}
										id="departments"
										value={
											selectedDepartment ? selectedDepartment : departments[0]
										}
										options={departments}
										onChange={onDepartmentChange}
										getOptionLabel={(option) => option.name}
										style={{ width: 300 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="الاقسام"
												variant="outlined"
											/>
										)}
									/>
								</React.Fragment>
							)
						) : null}
						{showCenters ? (
							<RowDiv>
								{showCenterCheck ? (
									<Checkbox
										size="small"
										className={classes.checkbox}
										name="show_departments_check"
										label="جميع المراكز؟"
										state={centerType == 'all' ? true : false}
										changeState={onIsAllCenterChange}
									/>
								) : null}
								{centerType == 'specific' ? (
									<Autocomplete
										size="small"
										className={classes.autocomplete}
										id="centers"
										value={selectedCenter ? selectedCenter : centers[0]}
										options={centers}
										onChange={onCenterChange}
										getOptionLabel={(option) => option.name}
										style={{ width: 300 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="المراكز والشعب"
												variant="outlined"
											/>
										)}
									/>
								) : null}
							</RowDiv>
						) : null}

						{showReport8Options ? (
							<Autocomplete
								size="small"
								className={classes.autocomplete}
								id="report8Options"
								value={
									selectedReport8Option
										? selectedReport8Option
										: report8OptionsList[0]
								}
								options={report8OptionsList}
								onChange={onReport8OptionChange}
								getOptionLabel={(option) => option.caption}
								style={{ width: 300 }}
								renderInput={(params) => (
									<TextField
										{...params}
										label="اختر النموذج"
										variant="outlined"
									/>
								)}
							/>
						) : null}
						{showClassChoice ? (
							<RowDiv>
								{showClassCheck ? (
									<Checkbox
										size="small"
										className={classes.checkbox}
										name="all_classes_or_one"
										label="جميع الاصناف؟"
										state={isAllClasses}
										changeState={onIsAllClassesChange}
									/>
								) : null}
								{isAllClasses ? null : (
									<Autocomplete
										size="small"
										className={classes.autocomplete}
										id="classes"
										value={selectedVClass ? selectedVClass : null}
										options={vClasses}
										onChange={onClassChange}
										getOptionLabel={(option) => option.name}
										style={{ width: 300 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="الفئات"
												variant="outlined"
											/>
										)}
									/>
								)}
							</RowDiv>
						) : null}
						<RowDiv>
							{showShifts ? (
								<Checkbox
									size="small"
									className={classes.checkbox}
									name="all_shifts_or_one"
									label="جميع الوجبات؟"
									state={isAllShifts}
									changeState={onIsAllShiftsChange}
								/>
							) : null}
							{showShifts ? (
								isAllShifts ? null : (
									<Autocomplete
										size="small"
										id="shifts"
										className={classes.autocomplete}
										value={selectedShift ? selectedShift : shifts[0]}
										options={shifts}
										onChange={onShiftChange}
										getOptionLabel={(option) => option.name}
										style={{ width: 300 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="الوجبات"
												variant="outlined"
											/>
										)}
									/>
								)
							) : null}
						</RowDiv>
						<RowDiv>
							{
								showVTChoice ? (
									<RowDiv>
										{showCheckOfVT ? (
											<Checkbox
												size="small"
												className={classes.checkbox}
												name="all_targets_or_one"
												label="كل المركبات؟"
												state={isAllTargets}
												changeState={onIsAllTargets}
											/>
										) : null}
										{isAllTargets ? null : (
											<Autocomplete
												size="small"
												className={classes.autocomplete}
												id="vehiclesTypes"
												value={
													selectedVehicleType
														? selectedVehicleType
														: vehiclesTypes[0]
												}
												options={vehiclesTypes}
												onChange={onVehicleTypeChange}
												getOptionLabel={(option) => option.name}
												style={{ width: 300 }}
												renderInput={(params) => (
													<TextField
														{...params}
														label="عائدية المركبة"
														variant="outlined"
													/>
												)}
											/>
										)}
									</RowDiv>
								) : null
								// <Autocomplete
								// 	size="small"
								// 	className={classes.autocomplete}
								// 	id="vehiclesTypes"
								// 	value={
								// 		selectedVehicleType ? selectedVehicleType : vehiclesTypes[0]
								// 	}
								// 	options={vehiclesTypes}
								// 	onChange={onVehicleTypeChange}
								// 	getOptionLabel={option => option.name}
								// 	style={{ width: 300 }}
								// 	renderInput={params => (
								// 		<TextField
								// 			{...params}
								// 			label="عائدية المركبة"
								// 			variant="outlined"
								// 		/>
								// 	)}
								// />
							}
						</RowDiv>
						<RowDiv>
							{showReasonCheck ? (
								<RowDiv>
									<Checkbox
										size="small"
										className={classes.checkbox}
										name="show_reason_check"
										label="كل الاسباب؟"
										state={isAllReasons}
										changeState={onIsAllReasons}
									/>
									{isAllReasons ? null : (
										<Autocomplete
											size="small"
											className={classes.autocomplete}
											id="reasons"
											value={
												selectedReason
													? selectedReason
													: reasons
													? reasons[0]
													: null
											}
											options={reasons}
											onChange={onReasonChange}
											getOptionLabel={(option) => option.name}
											style={{ width: 300 }}
											renderInput={(params) => (
												<TextField
													{...params}
													label="سبب الغياب"
													variant="outlined"
												/>
											)}
										/>
									)}
								</RowDiv>
							) : showReasons ? (
								<Autocomplete
									size="small"
									className={classes.autocomplete}
									id="reasons"
									value={
										selectedReason
											? selectedReason
											: reasons
											? reasons[0]
											: null
									}
									options={reasons}
									onChange={onReasonChange}
									getOptionLabel={(option) => option.name}
									style={{ width: 300 }}
									renderInput={(params) => (
										<TextField
											{...params}
											label="سبب الغياب"
											variant="outlined"
										/>
									)}
								/>
							) : null}
						</RowDiv>
						{renderInterval(selectedReport.id)}
						{intervalType === 'day' ? (
							<DayDate
								className={classes.pickers}
								style={{
									paddingTop: '15px',
								}}
								title="اليوم"
								selectedDate={selectedDayDate}
								handleDateChange={onDayDateChange}
							/>
						) : intervalType == 'month' ? (
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									autoOk={true}
									variant="inline"
									openTo="month"
									views={['year', 'month']}
									label="أختر الشهر"
									helperText="أختر الشهر"
									value={selectedMonth}
									onChange={handleMonthChange}
								/>
							</MuiPickersUtilsProvider>
						) : (
							<React.Fragment>
								<FromPicker
									className={classes.pickers}
									style={{
										paddingTop: '15px',
									}}
									title="من"
									selectedDate={selectedFrom}
									handleDateChange={onFromChange}
								/>
								<ToPicker
									className={classes.pickers}
									style={{
										paddingTop: '15px',
									}}
									title="الى"
									selectedDate={selectedTo}
									handleDateChange={onToChange}
								/>
							</React.Fragment>
						)}
						{intervalType == 'month' ? null : (
							<div
								style={{
									width: '70%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									marginTop: 10,
									marginBottom: 10,
								}}
							>
								<Button
									disabled={isRequesting}
									onClick={() => {
										setSelectedDayDate(moment()._d)
										setSelectedFrom(moment()._d)
										setSelectedTo(moment()._d)
										selectedIntervalOrDate('day')
										setboolean1(true)
										setboolean2(false)
										setboolean3(false)
										setboolean4(false)
									}}
									variant="outlined"
									color="secondary"
								>
									اليوم
								</Button>

								<Button
									disabled={isRequesting}
									onClick={() => {
										setSelectedDayDate(moment().add(-1, 'days')._d)
										setSelectedFrom(moment().add(-1, 'days')._d)
										setSelectedTo(moment()._d)
										selectedIntervalOrDate('day')
										setboolean1(false)
										setboolean2(true)
										setboolean3(false)
										setboolean4(false)
									}}
									variant="outlined"
									color="secondary"
								>
									الامس
								</Button>

								<Button
									disabled={isRequesting}
									onClick={() => {
										setSelectedDayDate(moment().add(-1, 'weeks')._d)
										setSelectedFrom(moment().add(-1, 'weeks')._d)
										setSelectedTo(moment().add(-1, 'days')._d)
										selectedIntervalOrDate('interval')
										setboolean1(false)
										setboolean2(false)
										setboolean3(true)
										setboolean4(false)
									}}
									variant="outlined"
									color="secondary"
								>
									قبل اسبوع
								</Button>

								<Button
									disabled={isRequesting}
									onClick={() => {
										setSelectedDayDate(moment().add(-1, 'months')._d)
										setSelectedFrom(moment().add(-1, 'months')._d)
										setSelectedTo(moment().add(-1, 'days')._d)
										selectedIntervalOrDate('interval')
										setboolean1(false)
										setboolean2(false)
										setboolean3(false)
										setboolean4(true)
									}}
									variant="outlined"
									color="secondary"
								>
									قبل شهر
								</Button>
							</div>
						)}
						<Button
							disabled={isRequesting}
							onClick={startExecute}
							variant="contained"
							color="primary"
						>
							تنفيذ
						</Button>
					</FormBox>
				)}
			</Container>
			{alert.show ? (
				<Snackbar
					title={alert.title}
					msg={alert.msg}
					show={alert.show}
					period={6000}
					severity={alert.color}
					closeSnackbar={closeSnackbar}
				></Snackbar>
			) : null}
		</Root>
	)
	function onTemplateChange(e, value) {
		if (value) setSelectedTemplate(value)
	}
	function onReport8OptionChange(e, value) {
		if (value) setSelectedReport8Option(value)
	}
	function onIsAllCenterChange(e) {
		setCenterType(e.target.checked ? 'all' : 'specific')
	}

	function handleMonthChange(e) {
		setSelectedMonth(e)
	}
	function onIsAllStationChange(e) {
		setStationType(e.target.checked ? 'all' : 'specific')
	}
	function onIsAllDepartmentChange(e) {
		setDepartmentType(e.target.checked ? 'all' : 'specific')
		if (e.target.checked) {
			setselectedDepartmentDemo(null)
		} else {
			setselectedDepartmentDemo(selectedDepartment)
		}
	}
	function onIsAllClassesChange(e) {
		if (selectedReport.id == 10 || selectedReport.id == 13) {
			// if (e.target.checked)
			// 	setIntervalType('day')
			// else
			// setIntervalType('month')
		}
		setIsAllClasses(e.target.checked)
	}
	function onIsAllTargets(e) {
		setIsOnTargets(e.target.checked)
	}
	function onIsAllReasons(e) {
		setReasonType(e.target.checked ? 'all' : 'specific')
		setIsAllReasons(e.target.checked)
	}

	async function onReportChange(e, value) {
		if (!value) return

		setDepartments(departments ? departments : [])
		await setSelectedDepartment(departments ? departments[0] : null)

		if (![10, 13]?.includes(value.id))
			setselectedDepartmentDemo(departments ? departments[0] : null)

		await setCenters(
			departments ? (departments[0].centers ? departments[0].centers : []) : []
		)
		await setSelectedCenter(
			selectedDepartment
				? selectedDepartment.centers
					? selectedDepartment.centers[0]
					: null
				: null
		)
		setShowRep1TemplateDive(false)

		if (value.id == 1) {
			setShowCheckOfVt(true)
			setIsOnTargets(false) //
			setShowReport8Options(false)
			setShowDepartmentCheck(false)
			setShowStationCheck(false)
			setShowStations(false)
			setShowDepartments(true)
			setIsAllDepartments(false)
			setDepartmentType('specific')
			setshowClassCheck(true)
			setShowClassChoice(true)
			setIsAllClasses(true)
			setShowShifts(true)
			setShowVTChoice(true)
			setShowStations(false)
			setStationType('all') // default
			setShowCenters(false)
			setShowCenterCheck(false)
			setSelectedFrom(new Date())
			setSelectedTo(new Date())
			setShowReasonCheck(false)
			setShowReasons(false)
			setShowClassChoice(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setShowRep1TemplateDive(true)
		}
		if (value.id == 2) {
			setShowCheckOfVt(true)
			setIsOnTargets(false) // synced with setShowCheckOfVt
			setShowReport8Options(false)
			setShowCenters(false)
			setShowCenterCheck(false)
			setShowDepartmentCheck(false)
			setIsAllDepartments(false)
			setShowStationCheck(false)
			setShowStations(false)
			setShowDepartments(true)
			setDepartmentType('specific')
			setShowClassChoice(true)
			setshowClassCheck(true)
			setIsAllClasses(false)
			setShowShifts(true)
			setIntervalType('interval')
			setShowVTChoice(true)
			setStationType('all') // default
			setSelectedFrom(new Date())
			setSelectedTo(new Date())
			setShowReasonCheck(false)
			setShowClassChoice(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
		}

		if (value.id == 3) {
			setIsOnTargets(false) //
			setShowCheckOfVt(false)
			setShowReport8Options(false)
			// freights - interval only, may be class_all,
			setShowCenters(false)
			setShowCenterCheck(false)
			setShowStationCheck(false)
			setShowDepartmentCheck(false)
			setIsAllDepartments(false)
			setDepartmentType('specific')
			setShowStations(false)
			setShowDepartments(true)
			setShowClassChoice(true)
			setshowClassCheck(true)
			setIsAllClasses(true)
			setShowVTChoice(true)
			setIntervalType('interval')
			setShowShifts(true)
			setStationType('all') // default

			let now = new Date() // now

			let date = subDays(now, 6)
			setSelectedFrom(date)
			setSelectedTo(now)
			setShowReasonCheck(false)
			setShowReasons(false)
			setShowClassChoice(true)
			setshowClassCheck(true)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
		} else if (value.id == 4) {
			setShowCheckOfVt(false)
			setIsOnTargets(false) //
			setShowReport8Options(false)
			setShowCenters(false)
			setShowCenterCheck(false)
			setShowDepartmentCheck(false)
			setShowDepartments(true)
			setDepartmentType('specific')
			setIsAllDepartments(false)
			setShowStationCheck(false)
			setShowStations(false)
			setShowClassChoice(true)
			setshowClassCheck(true)
			setIsAllClasses(true)
			setShowShifts(false)
			setIsAllShifts(false)
			setShowVTChoice(true)
			setStationType('all') // default
			setIntervalType('day')
			setSelectedDayDate(new Date())
			setShowReasonCheck(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setShowReasons(false)
		} else if (value.id == 5) {
			setselectedDepartmentDemo(null)
			setShowCheckOfVt(true)
			setIsOnTargets(false)
			setShowRep1TemplateDive(true)
			setShowReport8Options(false)
			setShowCenters(false)
			setShowCenterCheck(false)
			setShowDepartmentCheck(true)
			setIsAllDepartments(true)
			setShowStationCheck(false)
			setShowStations(false)
			setShowDepartments(true)
			setDepartmentType('all')
			setShowClassChoice(true)
			setshowClassCheck(true)
			setIsAllClasses(true)
			setShowShifts(true)
			setShowVTChoice(true)
			setStationType('all') // default
			setIntervalType('day')
			setSelectedDayDate(new Date())
			setSelectedFrom(new Date())
			setSelectedTo(new Date())
			setShowReasons(true)
			setShowReasonCheck(true)
			setIsAllReasons(true)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
		} else if (value.id == 6) {
			setShowCheckOfVt(false)
			setIsOnTargets(false)
			setShowReport8Options(false)
			setShowCenters(false)
			setShowCenterCheck(false)
			setShowDepartmentCheck(false)
			setShowDepartments(false)
			setDepartmentType('specific')
			setShowClassChoice(false)
			setIsAllDepartments(true)
			setshowClassCheck(false)
			setShowClassChoice(false)
			setIsAllClasses(false)
			setShowShifts(false)
			setIsAllShifts(false)
			setShowVTChoice(true)
			setIntervalType('day')
			setShowStationCheck(false)
			setShowStations(false)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')

			// alert();
		} else if (value.id == 7) {
			setShowCheckOfVt(false)
			setIsOnTargets(false)
			setShowReport8Options(false)
			// alert();
			setShowDepartments(true)
			setDepartmentType('specific')
			setShowDepartmentCheck(false)
			setShowCenters(false)
			setShowCenterCheck(false)
			setShowClassChoice(false)
			setshowClassCheck(false)
			setIsAllClasses(false)
			setShowShifts(false)
			setIsAllShifts(false)
			setShowVTChoice(false)
			setShowStationCheck(false)
			setShowStations(false)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setIntervalType('interval')
		} else if (value.id == 8) {
			setShowCheckOfVt(false)
			setIsOnTargets(false)
			setShowReport8Options(true)
			// attendence
			setShowDepartments(true)
			setDepartmentType('specific')
			setShowDepartmentCheck(false)
			setshowClassCheck(false)
			setShowClassChoice(false)
			setIsAllClasses(false)
			setShowShifts(false)
			setIsAllShifts(false)
			setShowVTChoice(true)
			setShowStationCheck(false)
			setShowStations(false)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setIntervalType('month')
			setShowCenters(true)
			setShowCenterCheck(true)
			setCenterType('specific')
		} else if (value.id == 9) {
			// karaj attendence report
			setIsOnTargets(false)
			setShowCheckOfVt(false)
			setShowReport8Options(false)
			setShowDepartments(true)
			setDepartmentType('specific')
			setShowDepartmentCheck(false)
			setshowClassCheck(true)
			setShowClassChoice(true)
			setIsAllClasses(true)
			setShowShifts(false)
			setIsAllShifts(false)
			setShowVTChoice(false)
			setShowStationCheck(false)
			setShowStations(false)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setIntervalType('month')
			setShowCenters(true)
			setShowCenterCheck(true)
			setCenterType('all')
		} else if (value.id == 10) {
			// weights report
			// setselectedDepartmentDemo(null)
			setIsOnTargets(false)
			setShowCheckOfVt(false)
			setShowReport8Options(false)
			setShowDepartments(true)
			setDepartmentType('specific')
			setShowDepartmentCheck(true)
			setshowClassCheck(true)
			setShowClassChoice(true)
			setIsAllClasses(false)
			setShowShifts(false)
			setIsAllShifts(false)
			setShowVTChoice(false)

			setShowStationCheck(false)
			setShowStations(false)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setIntervalType('month')
			setShowCenters(false)
			setShowCenterCheck(false)
			setCenterType('all')
		} else if (value.id == 11) {
			// drivers report
			setIsOnTargets(false)
			setShowCheckOfVt(false)
			setShowReport8Options(false)
			setShowDepartments(true)
			setDepartmentType('specific')
			setshowClassCheck(true)
			setShowClassChoice(true)
			setIsAllClasses(true)
			setShowShifts(true)
			setIsAllShifts(true)
			setShowVTChoice(true)
			setShowDepartmentCheck(false)
			setShowStationCheck(false)
			setShowStations(true)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setIntervalType('interval')
			setShowCenters(true)
			setShowCenterCheck(true)
			setCenterType('all')
		} else if (value.id == 12) {
			setShowCheckOfVt(true)
			setIsOnTargets(false)
			setShowReport8Options(false)
			// shafalat
			setShowDepartments(true)
			setDepartmentType('specific')
			setShowDepartmentCheck(false)
			setshowClassCheck(false)
			setShowClassChoice(false)
			setIsAllClasses(false)
			setShowShifts(true)
			setIsAllShifts(false)
			setShowVTChoice(true)
			setShowStationCheck(false)
			setShowStations(false)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setIntervalType('month')
			setShowCenters(true)
			setShowCenterCheck(true)
			setCenterType('specific')
		} else if (value.id == 13) {
			// weights with days report
			// setselectedDepartmentDemo(null)
			setIsOnTargets(false)
			setShowCheckOfVt(false)
			setShowReport8Options(false)
			setShowDepartments(true)
			setDepartmentType('specific')
			setShowDepartmentCheck(true)
			setshowClassCheck(true)
			setShowClassChoice(true)
			setIsAllClasses(false)
			setShowShifts(false)
			setIsAllShifts(false)
			setShowVTChoice(false)
			setShowStationCheck(false)
			setShowStations(false)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setIntervalType('month')
			setShowCenters(false)
			setShowCenterCheck(false)
			setCenterType('all')
		} else if (value.id == 14) {
			setShowCheckOfVt(false)
			setIsOnTargets(false)
			setShowReport8Options(false)
			// informal attendence
			setShowDepartments(true)
			setDepartmentType('specific')
			setShowDepartmentCheck(false)
			setshowClassCheck(true)
			setShowClassChoice(true)
			setIsAllClasses(false)
			setShowShifts(true)
			setIsAllShifts(true)
			setShowVTChoice(true)
			setShowStationCheck(false)
			setShowStations(false)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setIntervalType('month')
			setShowCenters(false)
			setShowCenterCheck(false)
			setCenterType('all')
		} else if (value.id == 15) {
			setShowCheckOfVt(false)
			setIsOnTargets(false)
			setShowReport8Options(false)
			// alert();
			setShowDepartments(true)
			setDepartmentType('specific')
			setShowDepartmentCheck(false)
			setShowCenters(false)
			setShowCenterCheck(false)
			setShowClassChoice(false)
			setshowClassCheck(false)
			setIsAllClasses(false)
			setShowShifts(false)
			setIsAllShifts(false)
			setShowVTChoice(false)
			setShowStationCheck(false)
			setShowStations(false)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setIntervalType('interval')
		} else if (value.id == 16) {
			setShowCheckOfVt(false)
			setIsOnTargets(false)
			setShowReport8Options(false)
			// alert();
			setShowDepartments(false)
			setDepartmentType('all')
			setShowDepartmentCheck(false)
			setShowCenters(false)
			setShowCenterCheck(false)
			setShowClassChoice(false)
			setshowClassCheck(false)
			setIsAllClasses(false)
			setShowShifts(false)
			setIsAllShifts(false)
			setShowVTChoice(false)
			setShowStationCheck(false)
			setShowStations(false)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setIntervalType('month')
		} else if (value.id == 17) {
			setShowCheckOfVt(false)
			setIsOnTargets(false)
			setShowReport8Options(false)
			// alert();
			setShowDepartments(false)
			setDepartmentType('all')
			setShowDepartmentCheck(false)
			setShowCenters(false)
			setShowCenterCheck(false)
			setShowClassChoice(false)
			setshowClassCheck(false)
			setIsAllClasses(false)
			setShowShifts(false)
			setIsAllShifts(false)
			setShowVTChoice(false)
			setShowStationCheck(false)
			setShowStations(false)
			setStationType('all') // default
			setShowReasonCheck(false)
			setShowReasons(false)
			setIsAllReasons(false)
			setSelectedReason(reasons ? reasons[0] : null)
			setReasonType('all')
			setIntervalType('month')
		}
		setSelectedReport(value)
	}
	function onDepartmentChange(e, value) {
		setSelectedDepartment(value)
		setselectedDepartmentDemo(value)
		setCenters(value.centers ? value.centers : null)
		setSelectedCenter(value.centers ? value.centers[0] : null)
	}
	function onCenterChange(e, value) {
		e.persist()
		setSelectedCenter(value)
	}
	function onStationChange(e, value) {
		if (value) setSelectedStation(value)
	}
	function onShiftChange(e, value) {
		setSelectedShift(value)
	}
	function onClassChange(e, value) {
		setSelectedVClass(value)
	}
	function onVehicleTypeChange(e, value) {
		setSelectedVehicleType(value)
	}
	function onReasonChange(e, value) {
		if (value) setSelectedReason(value)
	}

	function onIsAllShiftsChange(e) {
		setIsAllShifts(e.target.checked)
	}
	function onIntervalTypeChange(intervalType) {
		setIntervalType(intervalType)
		if (intervalType == 'month') {
			let d = new Date()
			d.setMonth(d.getMonth() - 1)
			setSelectedMonth(d)
		}
	}

	function onDayDateChange(e) {
		setSelectedDayDate(e)
	}
	function onFromChange(e) {
		console.log('from', e, moment().add(-1, 'days')._d)
		setSelectedFrom(e)
		if (selectedReport.id === 3) {
			// kaz

			let to = addDaysWithMoment(e, 6)
			setSelectedFrom(e)
			setSelectedTo(to)
		}
	}
	function addDays(dateObj, numDays) {
		let d = new Date()
		d.setDate(dateObj.getDate() + numDays)
		return d
	}
	function addDaysWithMoment(e, days) {
		return moment(e).add(days, 'days').toDate()
	}
	function subDays(dateObj, numDays) {
		let d = new Date()
		d.setDate(dateObj.getDate() - numDays)
		return d
	}
	function onToChange(e) {
		setSelectedTo(e)
	}
	function startExecute() {
		let errors = validate()
		if (errors.length > 0) {
			setAlert({
				show: true,
				color: 'warning',
				title: 'مدخلات خطأ',
				msg: formatErrorMgs2(errors),
			})
			return
		}
		//fetchDrivers()
		let data = configureDataParam()
		execute(data)

		setboolean1(false)
		setboolean2(false)
		setboolean3(false)
		setboolean4(false)
	}
	function configureDataParam() {
		let data = null
		switch (selectedReport?.id) {
			case 1:
				data = {
					report_id: selectedReport?.id,
					department_type: 'specific',
					department_id: selectedDepartment?.id,
					target_type: 'class',
					target_id: 1,
					specified_shift: isAllShifts ? 'all' : 'specific',
					interval_type: intervalType,
					target_vehicles: isAllTargets ? '0' : selectedVehicleType?.id,
					vehicle_type_id: isAllTargets ? '0' : selectedVehicleType?.id,
				}
				if (!isAllShifts)
					data = {
						...data,
						shift_id: selectedShift?.id,
					}

				if (intervalType === 'day') {
					data = {
						...data,
						day: configureDate(selectedDayDate),
						group_by: selectedTemplate.name,
					}
				} else {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
						group_by: selectedTemplate.name,
					}
				}

				break

			case 2:
				data = {
					report_id: selectedReport?.id,
					department_type: 'specific',
					department_id: selectedDepartment?.id,
					target_type: 'class',
					target_id: 1,
					specified_shift: isAllShifts ? 'all' : 'specific',
					interval_type: intervalType,
					target_vehicles: isAllTargets ? '0' : selectedVehicleType?.id,
					vehicle_type_id: isAllTargets ? '0' : selectedVehicleType?.id,
				}
				if (!isAllShifts)
					data = {
						...data,
						shift_id: selectedShift?.id,
					}

				if (intervalType === 'day') {
					data = {
						...data,
						day: configureDate(selectedDayDate),
					}
				} else {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
					}
				}
				break
			case 3:
				data = {
					report_id: selectedReport?.id,
					department_type: 'specific',
					department_id: selectedDepartment?.id,
					target_type: isAllClasses ? 'class_all' : 'class',
					specified_shift: isAllShifts ? 'all' : 'specific',
					interval_type: 'interval',
					target_vehicles: selectedVehicleType?.id,
					vehicle_type_id: selectedVehicleType?.id,
					from: configureDate(selectedFrom),
					to: configureDate(selectedTo),
				}
				if (!isAllClasses) {
					data = {
						...data,
						target_id: selectedVClass?.id,
					}
				}
				if (!isAllShifts) {
					data = {
						...data,
						shift_id: selectedShift?.id,
					}
				}

				break

			case 4:
				data = {
					report_id: selectedReport?.id,
					department_type: 'specific',
					department_id: selectedDepartment?.id,
					target_type: isAllClasses ? 'class_all' : 'class',
					specified_shift: 'specific',
					shift_id: 2, // صباحي
					interval_type: intervalType,
					target_vehicles: selectedVehicleType?.id,
					vehicle_type_id: selectedVehicleType?.id,
				}
				if (!isAllClasses) {
					data = {
						...data,
						target_id: selectedVClass?.id,
					}
				}
				if (intervalType === 'day') {
					data = {
						...data,
						day: configureDate(selectedDayDate),
					}
				} else {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
					}
				}
				break
			case 5:
				data = {
					report_id: selectedReport?.id,
					department_type: departmentType,
					center_type: centerType,
					center_selected:
						centerType == 'specific' ? selectedCenter?.id : 'all',

					target_type: isAllClasses ? 'class_all' : 'class',
					specified_shift: isAllShifts ? 'all' : 'specific',

					interval_type: intervalType,
					target_vehicles: isAllTargets ? '0' : selectedVehicleType?.id,
					vehicle_type_id: isAllTargets ? '0' : selectedVehicleType?.id,
					absence_reason_type: reasonType,
				}

				if (intervalType === 'day') {
					data = {
						...data,
						day: configureDate(selectedDayDate),
						group_by: selectedTemplate.name,
					}
				} else {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
						group_by: selectedTemplate.name,
					}
				}

				if (departmentType == 'specific') {
					data = {
						...data,
						department_id: selectedDepartment.id,
					}
				}
				if (!isAllShifts) {
					data = {
						...data,
						shift_id: selectedShift.id,
					}
				}
				if (!isAllClasses) {
					data = {
						...data,
						target_id: selectedVClass?.id,
					}
				}
				if (reasonType == 'specific') {
					data = {
						...data,
						absence_reason_id: selectedReason.id,
					}
				}
				break

			case 6:
				data = {
					report_id: selectedReport.id,
					target_type: 'class',
					target_id: 7, // const from server
					specified_shift: 'specific',
					interval_type: 'day',
					target_vehicles: selectedVehicleType.id,
					vehicle_type_id: selectedVehicleType.id,
					specified_shift: 'specific',
					shift_id: 2, // const from server side
					interval_type: 'day',
					day: configureDate(selectedDayDate),
				}

				break

			case 7:
				data = {
					report_id: selectedReport.id,
					department_type: departmentType,
					station_type: stationType,
					target_type: 'class_all',
					interval_type: intervalType,
					target_vehicles: '0',
					vehicle_type_id: '0',
				}
				if (departmentType == 'specific') {
					data = {
						...data,
						department_id: selectedDepartment.id,
					}
				}
				if (intervalType === 'month') {
					data = {
						...data,
						month: configureMonth(selectedMonth),
					}
				}
				if (intervalType === 'interval') {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
					}
				}

				if (stationType === 'specific') {
					data = {
						...data,
						station_id: selectedStation.id,
					}
				}

				break

			case 8:
				data = {
					department_type: 'specific',
					department_id: selectedDepartment.id,
					report_id: selectedReport.id,
					target_type: isAllClasses ? 'class_all' : 'class',
					interval_type: 'month',
					target_vehicles: selectedVehicleType.id,
					vehicle_type_id: selectedVehicleType.id,
					month: configureMonth(selectedMonth),
					center_type: centerType,
					vc_type: selectedReport8Option.name,
				}
				if (centerType == 'specific') {
					data = {
						...data,
						center_id: selectedCenter.id,
					}
				}
				if (!isAllClasses) {
					data = {
						...data,
						target_id: selectedVClass?.id,
					}
				}

				break

			case 9:
				data = {
					department_type: 'specific',
					department_id: selectedDepartment.id,
					report_id: selectedReport.id,
					target_type: isAllClasses ? 'class_all' : 'class',
					interval_type: 'month',
					target_vehicles: 1,
					vehicle_type_id: 1, // thatee only, anyway it set by server sode
					month: configureMonth(selectedMonth),
					center_type: centerType,
				}
				if (centerType == 'specific') {
					data = {
						...data,
						center_id: selectedCenter.id,
					}
				}
				if (!isAllClasses) {
					data = {
						...data,
						target_id: selectedVClass?.id,
					}
				}
				break

			case 10:
				data = {
					report_id: selectedReport.id,
					interval_type: intervalType == 'day' ? 'interval' : intervalType,
					target_type: isAllClasses ? 'class_all' : 'class',
					department_type: departmentType,

					// month: configureMonth(selectedMonth),
				}
				if (!isAllClasses) {
					data = {
						...data,
						target_id: selectedVClass?.id,
					}
				}
				if (intervalType == 'month') {
					data = {
						...data,
						month: configureMonth(selectedMonth),
					}
				}
				if (intervalType == 'interval') {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
					}
				}
				if (intervalType == 'day') {
					data = {
						...data,
						from: configureDate(selectedDayDate),
						to: configureDate(selectedDayDate),
					}
				}
				if (departmentType == 'specific') {
					data = {
						...data,
						department_id: selectedDepartment.id,
					}
				}
				break

			case 11:
				data = {
					report_id: selectedReport.id,
					interval_type: intervalType,
					department_type: 'specific',
					department_id: selectedDepartment.id,
					target_type: isAllClasses ? 'class_all' : 'class',
					specified_shift: isAllShifts ? 'all' : 'specific',
					target_vehicles: selectedVehicleType.id,
					vehicle_type_id: selectedVehicleType.id,
					center_type: centerType,
					// month: configureMonth(selectedMonth),
				}
				if (!isAllClasses) {
					data = {
						...data,
						target_id: selectedVClass?.id,
					}
				}
				if (!isAllShifts) {
					data = {
						...data,
						shift_id: selectedShift.id,
					}
				}
				if (intervalType == 'month') {
					data = {
						...data,
						month: configureMonth(selectedMonth),
					}
				}
				if (intervalType == 'interval') {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
					}
				}
				if (centerType == 'specific') {
					data = {
						...data,
						center_id: selectedCenter.id,
					}
				}
				break
			case 12:
				data = {
					department_type: 'specific',
					department_id: selectedDepartment.id,
					vehicle_type_id: isAllTargets ? '0' : selectedVehicleType.id,
					report_id: selectedReport.id,
					interval_type: intervalType,
					center_type: centerType,
					specified_shift: isAllShifts ? 'all' : 'specific',
				}
				if (centerType == 'specific') {
					data = {
						...data,
						center_id: selectedCenter.id,
					}
				}
				if (!isAllShifts) {
					data = {
						...data,
						shift_id: selectedShift.id,
					}
				}

				if (intervalType == 'month') {
					data = {
						...data,
						month: configureMonth(selectedMonth),
					}
				}
				if (intervalType == 'interval') {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
					}
				}
				break
			case 13:
				data = {
					report_id: selectedReport.id,
					interval_type: intervalType == 'day' ? 'interval' : intervalType,
					target_type: isAllClasses ? 'class_all' : 'class',
					department_type: departmentType,

					// month: configureMonth(selectedMonth),
				}
				if (!isAllClasses) {
					data = {
						...data,
						target_id: selectedVClass?.id,
					}
				}
				if (intervalType == 'month') {
					data = {
						...data,
						month: configureMonth(selectedMonth),
					}
				}
				if (intervalType == 'interval') {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
					}
				}
				if (intervalType == 'day') {
					data = {
						...data,
						from: configureDate(selectedDayDate),
						to: configureDate(selectedDayDate),
					}
				}
				if (departmentType == 'specific') {
					data = {
						...data,
						department_id: selectedDepartment.id,
					}
				}
				break
			case 14:
				data = {
					department_type: 'specific',
					department_id: selectedDepartment.id,
					report_id: selectedReport.id,
					target_type: isAllClasses ? 'class_all' : 'class',
					specified_shift: isAllShifts ? 'all' : 'specific',
					target_vehicles: selectedVehicleType.id,
					vehicle_type_id: selectedVehicleType.id,
					interval_type: intervalType,
					center_type: centerType ? centerType : 'all',
				}
				if (!isAllClasses) {
					data = {
						...data,
						target_id: selectedVClass?.id,
					}
				}
				if (!isAllShifts)
					data = {
						...data,
						shift_id: selectedShift.id,
					}
				if (intervalType == 'month') {
					data = {
						...data,
						month: configureMonth(selectedMonth),
					}
				}
				if (intervalType == 'interval') {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
					}
				}

				break
			case 15:
				data = {
					report_id: selectedReport.id,
					department_type: departmentType,
					station_type: stationType,
					target_type: 'class_all',
					interval_type: intervalType,
					target_vehicles: '0',
					vehicle_type_id: '0',
				}
				if (departmentType == 'specific') {
					data = {
						...data,
						department_id: selectedDepartment.id,
					}
				}
				if (intervalType === 'month') {
					data = {
						...data,
						month: configureMonth(selectedMonth),
					}
				}
				if (intervalType === 'interval') {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
					}
				}

				if (stationType === 'specific') {
					data = {
						...data,
						station_id: selectedStation.id,
					}
				}

				break
			case 16:
				data = {
					report_id: selectedReport.id,
					interval_type: intervalType,
				}
				if (intervalType === 'month') {
					data = {
						...data,
						month: configureMonth(selectedMonth),
					}
				}
				if (intervalType === 'interval') {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
					}
				}

				break
			case 17:
				data = {
					report_id: selectedReport.id,
					interval_type: intervalType,
				}
				if (intervalType === 'month') {
					data = {
						...data,
						month: configureMonth(selectedMonth),
					}
				}
				if (intervalType === 'interval') {
					data = {
						...data,
						from: configureDate(selectedFrom),
						to: configureDate(selectedTo),
					}
				}

				break

			default:
				break
		}

		return data
	}
	function validate() {
		let errors = []
		if (intervalType === 'interval') {
			let fr_ = configureDate(selectedFrom)
			let to_ = configureDate(selectedTo)
			if (fr_ > to_) {
				errors.push('تأكد من فترة تنفيذ التقرير!')
			}
		}
		if (intervalType == 'month') {
			if (!selectedMonth) errors.push('قم بأختيار الشهر')
		}
		if (showCenters) {
			if (centerType == 'specific') {
				console.log('selected center in validation function is')
				console.log(selectedCenter)
				if (!selectedCenter) errors.push('يرحى اختيار المركز')
			}
		}
		return errors
	}

	// async function fetchDrivers() {
	// 	 await axios({
	// 			url: url + `/drivers`,
	// 			method: 'GET',
	// 			headers: {
	// 				'Content-Type': 'application/x-www-form-urlencoded',
	// 				Authorization: 'Bearer ' + userData.token
	// 			}
	// 		}).then(response => {
	// 		setDrivers(response.data.Data)
	// 		})
	// 	 .catch (error =>{
	// 		let code = getStatusCode(error)
	// 		let errCodeObj = handleErr(code, error)
	// 		// setErrCode(errCodeObj)
	// 		setIsLoading(false)
	// 		setIsRequesting(false)
	// 		setAlert({
	// 			title: 'حدث خطأ ما',
	// 			msg: errCodeObj.how_to_fix,
	// 			color: 'warning',
	// 			show: true
	// 		})

	// 	})
	// }

	function execute(data) {
		//console.log(data)
		setIsRequesting(true)
		setIsLoading(true)

		// axios.post(url + `/reports/execute`, data , {
		// 			headers: {
		// 				'Content-Type': 'application/json',
		// 				Authorization: 'Bearer ' + userData.token
		// 			},
		// 			cancelToken: cancelTokenSource.token, // 2nd step
		// 		})
		axios({
			url: url + `/reports/execute`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + userData.token,
			},
			data: data,
			cancelToken: cancelSource.current.token,
			timeout: 1020000, // Timeout duration in milliseconds
		})
			.then((response) => {
				console.log(response.data.Data)
				window.open(response.data.Data)

				setIsLoading(false)
				setIsRequesting(false)
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					setIsLoading(false)
					setIsRequesting(false)
					setAlert({
						title: 'تم الغاء تحميل التقرير',
						msg: 'تم الغاء تحميل التقرير',
						color: 'warning',
						show: true,
					})
				} else {
					let code = getStatusCode(error)
					let errCodeObj = handleErr(code, error)
					// setErrCode(errCodeObj)
					setIsLoading(false)
					setIsRequesting(false)
					setAlert({
						title: 'لم يتم تحميل التقرير',
						msg: errCodeObj.how_to_fix,
						color: 'warning',
						show: true,
					})
				}
			})
	}
	function closeSnackbar(event, reason) {
		if (reason === 'clickaway') {
			return
		}
		setAlert({
			show: false,
			title: '',
			msg: '',
			color: '',
		})
	}

	function renderInterval(reportID) {
		switch (reportID) {
			case 1:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('day')}>يوم</Button>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)

			case 2:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('day')}>يوم</Button>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)

			case 3:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)

			case 4:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('day')}>يوم</Button>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)

			case 5:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('day')}>يوم</Button>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)
			case 6:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('day')}>يوم</Button>
						{/* <Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button> */}
					</ButtonGroup>
				)
			case 7:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('month')}>شهر</Button>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)

			case 10:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('month')}>شهر</Button>
						{/* {!isAllClasses ? ( */}
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
						{/* ) : null} */}
						{/* {isAllClasses ? <Button onClick={() => onIntervalTypeChange('day')}>يوم</Button> : null} */}
					</ButtonGroup>
				)
			case 11:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('month')}>شهر</Button>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)

			case 12:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('month')}>شهر</Button>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)
			case 13:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('month')}>شهر</Button>
						{/* {!isAllClasses ? ( */}
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
						{/* ) : null} */}
						{/* {isAllClasses ? <Button onClick={() => onIntervalTypeChange('day')}>يوم</Button> : null} */}
					</ButtonGroup>
				)
			case 14:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('month')}>شهر</Button>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)
			case 15:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('month')}>شهر</Button>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)
			case 16:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('month')}>شهر</Button>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)
			case 17:
				return (
					<ButtonGroup
						className={classes.bg}
						color="primary"
						aria-label="small outlined primary button group"
					>
						<Button onClick={() => onIntervalTypeChange('month')}>شهر</Button>
						<Button onClick={() => onIntervalTypeChange('interval')}>
							فترة
						</Button>
					</ButtonGroup>
				)
		}
	}

	function selectedIntervalOrDate(interOrDay) {
		if (interOrDay == 'day') {
			if (
				selectedReport.id != 3 &&
				selectedReport.id != 7 &&
				selectedReport.id != 11 &&
				selectedReport.id != 12 &&
				selectedReport.id != 13
			) {
				onIntervalTypeChange('day')
			}
		} else {
			if (selectedReport.id != 6) {
				onIntervalTypeChange('interval')
			}
		}
	}
}

export default Reports
