import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBox from '../../common/CheckBox'
import TextField from '@material-ui/core/TextField'

import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import rtl from 'jss-rtl'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const colors = require('../../../assets/files/env.json').theme.colors

const theme = createMuiTheme({
	direction: 'rtl',
	typography: {
		fontFamily: ['Cairo', 'Arial', 'sans-serif'].join(','),
	},
})

const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
`
const SubFContainers = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	flex-wrap: wrap;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
	background-color: '#fff';
`

const DataDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: baseline;
	padding: 5px;
	border: 1px solid #9c9c9c;
	width: 100%;
	border-radius: 5px;
`

const useStyles = makeStyles((theme) => ({
	autocomplete: {
		marginTop: '15px',
		minWidth: '500px',
		backgroundColor: '#fff',
	},
	pickers: {
		backgroundColor: '#fff',
	},
	textField: {
		backgroundColor: '#fff',
		marginTop: '10px',
	},
}))

function PaperComponent(props) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	)
}

export default function DraggableDialog(props) {
	const classes = useStyles()
	const handleEnteredEvent = (e) => {
		if (!props?.isRequesting) {
			if (e.key === 'Enter') {
				if (e.target.id === 'note_thatee' || e.target.id === 'note_moajar') {
					e.stopPropagation() // Stop propagation of the event
				} else {
					props.save() // Handle the event for other elements
				}
			} else if (e.key === 'Escape') {
				props.closeAddModal()
			}
		}
	}
	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<Dialog
					fullWidth={true}
					maxWidth="lg"
					disableBackdropClick={true}
					open={props.open}
					onClose={props.closeAddModal}
					PaperComponent={PaperComponent}
					aria-labelledby="draggable-dialog-title"
					onKeyDown={handleEnteredEvent}
				>
					<DialogTitle
						style={{
							backgroundColor: '#007AC4',
							color: '#fff',
							cursor: 'move',
						}}
						id="draggable-dialog-title"
					>
						واجهة انشاء صنف مركبة جديد
					</DialogTitle>
					<DialogContent
						style={{
							backgroundColor: '#DEF3FF',
						}}
					>
						<Root>
							<TextField
								className={classes.textField}
								id="name"
								name="name"
								label="اسم الصنف"
								variant="outlined"
								onChange={props.changeField}
								value={props.fieldsData.name}
							/>
							<TextField
								className={classes.textField}
								id="descr"
								name="descr"
								label="الوصف"
								variant="outlined"
								onChange={props.changeField}
								value={props.fieldsData.descr}
								style={{
									width: '100%',
								}}
							/>
							<TextField
								className={classes.textField}
								id="note_thatee"
								name="note_thatee"
								label="ملاحظات الذاتي"
								variant="outlined"
								multiline
								onChange={props.changeField}
								value={props.fieldsData.note_thatee}
								style={{
									width: '100%',
								}}
							/>
							<TextField
								className={classes.textField}
								id="note_moajar"
								name="note_moajar"
								multiline
								label="ملاحظات المؤجر"
								variant="outlined"
								onChange={props.changeField}
								value={props.fieldsData.note_moajar}
								style={{
									width: '100%',
								}}
							/>
							<CheckBox
								name="is_in"
								label="جهد الداخل؟"
								state={props.fieldsData.is_in}
								changeState={(e)=>props.changeChecks(e,1)}
							></CheckBox>
							<CheckBox
								name="is_out"
								label="جهد الخارج؟"
								state={props.fieldsData.is_out}
								changeState={(e)=>props.changeChecks(e,2)}
							></CheckBox>
						</Root>
					</DialogContent>
					<DialogActions>
						<Button
							disabled={props.isRequesting}
							autoFocus
							onClick={props.closeAddModal}
							color="primary"
						>
							الغاء
						</Button>
						<Button
							disabled={props.isRequesting}
							onClick={props.save}
							color="primary"
						>
							حفظ
						</Button>
					</DialogActions>
				</Dialog>
			</StylesProvider>
		</ThemeProvider>
	)
}
