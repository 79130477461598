import React from 'react'
import logo from './logo.svg'
import './App.css'
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom'
import Main from './components/pages/Main/Main.js'
import Login from './components/pages/login/Login'
import Context from './components/common/Context'
import Cookies from 'js-cookie'
import { withAppContext } from './context/Context'

import { getBaseURL } from './components/common/Utils'
import NotFount from './components/pages/NotFount'

const url = getBaseURL()

class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isAuthorized: false,
		}
		this.setAuth = this.setAuth.bind(this)
		// this.checkLoginTime = this.checkLoginTime.bind(this)
	}
	componentDidMount() {
		let data = null
		if (Cookies.get('SDIMS_USER_DATA')) {
			try {
				data = JSON.parse(Cookies.get('SDIMS_USER_DATA'))
				if (data) this.setState({ isAuthorized: true })
			} catch (error) {}
		}
		// Listen for route changes
		this.interval = setInterval(this.checkLoginTime, 1000) // Check every second
	}
	componentWillUnmount() {
		clearInterval(this.interval) // Clear the interval on unmount
	}

	checkLoginTime = () => {
		console.log('sdfsdfds===>started')
		const savedLoginTime = localStorage.getItem('loginTime')
		console.log('sdfsdfds===>loginTime:', savedLoginTime)

		if (savedLoginTime && savedLoginTime !== 'null') {
			console.log('sdfsdfds===>started')
			console.log('sdfsdfds===>loginTime:pass validation')

			const currentTime = new Date()
			const loginTime = new Date(savedLoginTime)
			const timeElapsed = currentTime - loginTime // Calculate elapsed time in milliseconds
			console.log('sdfsdfds===>loginTime:time in milliseconds:',timeElapsed)

			// Check if 12 hours have passed (12 * 60 * 60 * 1000 milliseconds)
			if (timeElapsed >= 12 * 60 * 60 * 1000) {
			   console.log('sdfsdfds===>loginTime:login time end, must log out')
				this.handleLogout() // Call the logout function
			}
		}
	}

	handleLogout = () => {
		console.log('Logging out due to inactivity')
		// Clear the stored login time and user info
		localStorage.removeItem('loginTime')
		localStorage.removeItem('loginTime')
		if(Cookies.get('SDIMS_USER_DATA'))
		Cookies.remove('SDIMS_USER_DATA')
		alert('انتهة الجلسة الخاصة بك')
		window.location.href = '/'
	}
	render() {
		// console.log(this.props)
		return (
			<BrowserRouter basename="/">
				<Switch>
					<Route exact path="/" component={Login} />
					<Route path="/dashboard" component={Main} />
					<Route path="/reports" component={Main} />
					<Route path="/users" component={Main} />
					<Route path="/vehicles" component={Main} />
					<Route path="/drivers" component={Main} />
					<Route path="/freights" component={Main} />
					<Route path="/centers" component={Main} />
					{/* <Route path="/notes" component={Main} /> */}
					{/* <Route path="/WeightNotes" component={Main} /> */}
					<Route path="/VehiclesNotAllow" component={Main} />
					<Route path="/Vehicle-classes" component={Main} />
					<Route path="/name-of-officials" component={Main} />
					<Route path="/departments" component={Main} />

					<Route path="*" component={NotFount} />
				</Switch>
			</BrowserRouter>
		)
	}

	setAuth(newState) {
		this.setState({ isAuthorized: newState })
	}
}

export default withAppContext(App)
