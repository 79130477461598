import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import MyTableRow from '../../common/TableRow'
import MyTableCell from '../../common/CustomTableCell'
import DeleteIcon from '@material-ui/icons/Delete'
import AssignmentIcon from '@material-ui/icons/Assignment'


const headers = require('../../../assets/files/headers.json').Departments

const crudColors = require('../../../assets/files/env.json').theme.colors.crud
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		borderBottom: '2px solid #c4ddec',
	},
}))(TableRow)

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
})

function CustomizedTables(props) {
	const classes = useStyles()
	let numbering = 0
	let prop = props
	return (
		<TableContainer component={Paper}>
			<Table
				size="small"
				className={classes.table}
				aria-label="customized table"
			>
				<TableHead>
					<MyTableRow header={headers}></MyTableRow>
				</TableHead>
				<TableBody>
					{props?.data?.map(function (item, index) {
						numbering++
						return (
							<StyledTableRow key={index}>
								<MyTableCell
									key={'vehicleClass_numbering' + index}
									value={item?.id}
									style={{
										width: '10%',
									}}
								></MyTableCell>

								<MyTableCell
									key={'vehicleClass_name' + item.id}
									value={item.name}
									style={{
										width: '25%',
									}}
								></MyTableCell>
								<MyTableCell
									key={'vehicleClass_description' + item.id}
									value={item?.descr}
									style={{
										width: '40%',
									}}
								></MyTableCell>
								<StyledTableCell
									key={'center_edit' + item.id}
									align="right"
									style={{
										width: '100px',
									}}
								>
									<IconButton
										onClick={() =>
											prop.showEditModalFun({
												vehicleClass: item,
												index: index,
											})
										}
									>
										<EditIcon style={{ color: crudColors.u }} />
									</IconButton>
								</StyledTableCell>
								{item?.id && item?.id > 7 ? (
									<StyledTableCell
										key={'center_delete' + item.id}
										align="right"
										style={{
											width: '100px',
										}}
									>
										<IconButton
											onClick={() =>
												prop.showDeleteModalFun({
													vehicleClass: item,
													index: index,
												})
											}
										>
											<DeleteIcon style={{ color: crudColors.d }} />
										</IconButton>
									</StyledTableCell>
								) : (
									<StyledTableCell
										key={'center_delete' + item.id}
										align="right"
										style={{
											width: '100px',
										}}
									></StyledTableCell>
								)}
								<StyledTableCell
									key={'center_assignment' + item.id}
									align="right"
									style={{
										width: '120px',
									}}
								>
									<IconButton
										onClick={() =>
											props?.assignVehicleClassToDepartment(item)
										}
									>
										<AssignmentIcon style={{ color: crudColors.u }} />
									</IconButton>
								</StyledTableCell>
							</StyledTableRow>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default CustomizedTables
